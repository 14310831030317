import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { EventSelectDateBtn } from '../../select-delivery-date/enums/select-delivery-dates.enum';

import { SelectFrozenDeliveryDateDesktopComponent } from '../../select-frozen-delivery-date-desktop/select-frozen-delivery-date-desktop.component';

@Injectable({
  providedIn: 'root'
})
export class ModalDesktopFrozenDeliveryDateService {
  modalRef: NgbModalRef;
  backdrop: boolean | 'static' = 'static';

  constructor(private ngbModalService: NgbModal) { }

  openModal(): NgbModalRef {
    this.modalRef = this.ngbModalService.open(SelectFrozenDeliveryDateDesktopComponent, {
      centered: true,
      size: 'sm',
      backdrop: this.backdrop,
      container: 'app-main',
    });

    return this.modalRef;
  }

  closeModal(btnEvent: EventSelectDateBtn): void {
    this.modalRef.close(btnEvent);
  }
}
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { cleanCart } from 'src/app/core/state/actions/cart.actions';
import * as PortfolioActions from 'src/app/core/state/actions/portfolio.actions';

@Component({
  selector: 'app-alert-warm-modal',
  templateUrl: './alert-warm-modal.component.html',
  styleUrls: ['./alert-warm-modal.component.scss'],
})
export class AlertWarmModalComponent {
  readonly LANG_ROOT = 'NEW_ORDER.ORDER_DETAIL.MODAL_ALERT_WARM.';

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store
    ) { }

  confirm(): void {
    this.activeModal.close(true);
    this.store.dispatch(cleanCart());
    this.store.dispatch(PortfolioActions.cleanProductFilters());
  }

  reject(): void {
    this.activeModal.dismiss();
  }
}

@let showSmartDates = ambientSmartDeliveryVisitDates.length > 0;

@if((client$ | async); as client) {
  <radio-group [formControl]="control">
    @if(showSmartDates) {
      <div class="select-date-options-container smart-container">
        <div class="select-date-options-title smart-title">
          {{'NEW_ORDER.DELIVERY_PLAN.SMART_DELIVERY_TITLE' | translate}}
        </div>
      
        <div class="select-date-options-subtitle">
          {{ 'NEW_ORDER.DELIVERY_PLAN.SMART_DELIVERY_SUBTITLE' | translate }}
        </div>
        <div class="mb-16"></div>
      
        <div class="radio-container">
          <ng-container *ngFor="let date of ambientSmartDeliveryVisitDates; trackBy: trackByDates">
            <div class="radio-option">
              <ng-container *ngTemplateOutlet="datesTemplate; context: { date: date }"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>      
    }
  
    @if(ambientOnOffSmartVisitDates.length > 0) {
      <div 
        class="select-date-options-container"
        [ngClass]="{'paddint-top-zero' : showSmartDates}">

        @if(showSmartDates) {
          <div class="select-date-options-title mb-16">
            {{ 'NEW_ORDER.DELIVERY_PLAN.MORE_DATES_AVAILABLE' | translate }}
          </div>
        }

        <div class="radio-container">
          <ng-container *ngFor="let date of ambientOnOffSmartVisitDates; trackBy: trackByDates">
            <div class="radio-option">
              <ng-container *ngTemplateOutlet="datesTemplate; context: { date: date }"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    }

    <ng-template #datesTemplate let-date="date">
      <div class="radio-option-info">
        <app-radio [value]="date">
          {{date.visitDate | weekdayDayMonthParser}}
        </app-radio>
    
        @if(date | canShowLabelAditionalSmartDate) {
          <app-label 
            [text]="'NEW_ORDER.DELIVERY_PLAN.ADITIONAL_SD_DATE' | translate" 
            [class]="'success'">
          </app-label>
        }
      </div>

      <app-min-order-label [date]="date"></app-min-order-label>
    </ng-template>
  </radio-group>
}

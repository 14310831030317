import { AfterViewInit, Component, inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription, take } from 'rxjs';

import { Cart } from 'src/app/core/models/cart.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { Client } from 'src/app/core/models/client.model';

import { 
  getCartMinOrderAmbient,
  getCartMinOrderFrozen,
  getCartTotalBoxes, 
  getCartTotalPriceFrozen, 
  getCartTotalPriceNotFrozen, 
  hasCartDeliveryFrozenProducts, 
  hasCartDeliveryNotFrozenProducts 
} from 'src/app/core/state/selectors/cart.selectors';

import { CartService } from 'src/app/core/services/cart.service';
import { MinOrder } from 'src/app/core/services/min-amount/interface/min-order.interface';

enum progressBarStatus {
  COMPLETED   = 'success',
  INCOMPLETED = 'danger',
}

@Component({
  selector: 'app-ka-minimun-purchase',
  templateUrl: './ka-minimun-purchase.component.html',
  styleUrls: ['./ka-minimun-purchase.component.scss'],
})
export class KaMinimunPurchaseComponent implements OnDestroy, AfterViewInit {
  private cartService = inject(CartService);
  readonly ROOT = 'NEW_ORDER.RIGHT_SIDEBAR.';
  percentNumberBoxes;
  percentAmount;
  cart: Cart;
  user: UserInfo;
  percentAmountFrozen;
  client: Client;
  cartHasDeliveryFrozenProducts?: boolean;
  cartHasDeliveryNotFrozenProducts?: boolean;
  private subscriptions = new Subscription();
  readonly ProgressBarStatus = progressBarStatus;
  get minOrderAmbient$(): Observable<MinOrder> {
    return this.store.select(getCartMinOrderAmbient);
  };
  get minOrderFrozen$(): Observable<MinOrder> {
    return this.store.select(getCartMinOrderFrozen);
  };

  constructor(private store: Store<{ user: UserInfo; client: Client; cart: Cart }>) {
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));

    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      })
    );
    this.subscriptions.add(this.store.select(hasCartDeliveryFrozenProducts).subscribe((hasDeliveryFrozenProducts) => (this.cartHasDeliveryFrozenProducts = hasDeliveryFrozenProducts)));
    this.subscriptions.add(this.store.select(hasCartDeliveryNotFrozenProducts).subscribe((hasDeliveryNotFrozenProducts) => (this.cartHasDeliveryNotFrozenProducts = hasDeliveryNotFrozenProducts)));
    
    this.subscriptions.add(combineLatest([
      this.store.select(getCartMinOrderAmbient),
      this.store.select(getCartTotalBoxes),
    ]).subscribe(([minOrderAmbient, cartTotalBoxes]) => {
      this.percentNumberBoxes = (cartTotalBoxes / minOrderAmbient.value) * 100;
    }));
    this.subscriptions.add(combineLatest([
      this.store.select(getCartMinOrderAmbient),
      this.store.select(getCartTotalPriceNotFrozen),
    ]).subscribe(([minOrderAmbient, cartTotalPriceNotFrozen]) => {
      this.percentAmount = (cartTotalPriceNotFrozen / minOrderAmbient.value) * 100;
    }));
    this.subscriptions.add(combineLatest([
      this.store.select(getCartMinOrderFrozen),
      this.store.select(getCartTotalPriceFrozen),
    ]).subscribe(([minOrderFrozen, cartTotalPriceFrozen]) => {
      this.percentAmountFrozen = (cartTotalPriceFrozen / minOrderFrozen.value) * 100;
    }));
  }

  ngAfterViewInit(): void {
    this.minOrderAmbient$
      .pipe(take(1))
      .subscribe({
        next: (minOrderAmbient) => {
          if (
            this.cartHasDeliveryNotFrozenProducts && !this.cart.offRoute && minOrderAmbient.value !== 0 ||
            this.cartHasDeliveryFrozenProducts && !this.cart.offRoute ||
            this.cart.offRoute
          ) {
            this.updateHeight('85');
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.updateHeight('100');
  }

  private updateHeight(height: string): void {
    const element = document.getElementById('s2ProductsComponent');
    if (element) {
      element.style.height = `calc(var(--vh, 1vh) * ${height} - 40px)`;
    }
  }
}

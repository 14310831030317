import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { UserInfo } from '../../models/user-info.model';
import * as UserActions from '../actions/user.actions';
import { ExternalIntegration } from 'src/app/pages/external-integration/enums/external-integration.enum';

const getUserState = createFeatureSelector<UserInfo>('user');

export const isAuthenticated = createSelector(getUserState, (user) => {
  return user && user.jwt && user.userId;
});

export const getUserClients = createSelector(getUserState, (state) => state.clients);
export const getUserUuid = createSelector(getUserState, (state) => state.uuid);

export const getUserCountryId = createSelector(getUserState, (state) => {
  return state.countryId;
});

export const isExternalUser = createSelector(
  getUserState,
  (user: UserInfo) =>
    (user?.authValue && user?.origin) === ExternalIntegration.KOBOSS,
);

const initialState: UserInfo = {
  uuid: '',
  isSocial: false,
  devicePlatform: '',
  device: ''
};

export const userReducer = createReducer<UserInfo>(
  initialState,
  on(UserActions.loadUser, (state, props): UserInfo => ({ ...state, ...props.user })),
  on(UserActions.loadRole, (state, props): UserInfo => ({ ...state, role: props.role })),
  on(UserActions.loadJwt, (state, props): UserInfo => ({ ...state, jwt: props.jwt })),
  on(UserActions.loadRefreshJwt, (state, props): UserInfo => ({ ...state, refreshJwt: props.refreshJwt })),
  on(UserActions.loadCognitoUserName, (state, props): UserInfo => ({ ...state, cognitoUserName: props.cognitoUserName })),
  on(UserActions.loadUuid, (state, props): UserInfo => ({ ...state, uuid: props.uuid })),
  on(UserActions.logout, (state): UserInfo => ({ ...state })),

  on(UserActions.loginUser, (state): UserInfo => ({ ...state })),
  on(UserActions.loginUserSuccess, (state): UserInfo => ({ ...state })),
  on(UserActions.loginUserError, (state): UserInfo => ({ ...state, ...initialState })),

  on(UserActions.updateUser, (state): UserInfo => ({ ...state })),
  on(UserActions.updateUserSuccess, (state, props): UserInfo => ({ ...state, ...props.user })),
  on(UserActions.updateUserError, (state): UserInfo => ({ ...state, ...initialState })),

  on(UserActions.loginIntegrationUser, (state): UserInfo => ({ ...state })),
  on(UserActions.loginIntegrationUserSuccess, (state): UserInfo => ({ ...state})),
  on(UserActions.loginIntegrationUserError, (state): UserInfo => ({ ...state, ...initialState })),

  on(UserActions.getIntegrationUserInfo, (state): UserInfo => ({ ...state })),
  on(UserActions.getIntegrationUserInfoSuccess, (state): UserInfo => ({ ...state })),
  on(UserActions.getIntegrationUserInfoError, (state): UserInfo => ({ ...state, ...initialState })),

  on(UserActions.isSocial, (state, props): UserInfo => ({ ...state, isSocial: props.isSocial })),

  on(UserActions.setDeviceInfo, (state, props): UserInfo => ({ ...state, devicePlatform: props.devicePlatform, device: props.device })),

  on(UserActions.loadDeviceInfo, (state, props): UserInfo => ({ ...state })),
  on(UserActions.loadDeviceInfoSuccess, (state): UserInfo => ({ ...state })),
  on(UserActions.loadDeviceInfoError, (state): UserInfo => ({ ...state, ...initialState })),

  on(UserActions.loginExternalIntegrationUser, (state): UserInfo => ({ ...state })),
  on(UserActions.loginExternalIntegrationUserSuccess, (state): UserInfo => ({ ...state })),
  on(UserActions.loginExternalIntegrationUserError, (state): UserInfo => ({ ...state, ...initialState })),

  on(UserActions.setExternalIntegrationUserInfo, (state, props): UserInfo => ({ ...state, authType: props.authType, authValue: props.authValue, origin: props.origin })),

  on(UserActions.getExternalIntegrationUserInfo, (state): UserInfo => ({ ...state })),
  on(UserActions.getExternalIntegrationUserInfoSuccess, (state): UserInfo => ({ ...state })),
  on(UserActions.getExternalIntegrationUserInfoError, (state): UserInfo => ({ ...state, ...initialState })),

  on(UserActions.createUserSession, (state): UserInfo => ({ ...state })),
  on(UserActions.createUserSessionSuccess, (state): UserInfo => ({ ...state })),
  on(UserActions.createUserSessionError, (state): UserInfo => ({ ...state, ...initialState })),
  on(UserActions.cleanUserInformation, (state):UserInfo => (initialState)),
);

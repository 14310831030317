import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { BoxesPipe } from '../../pipes/boxes.pipe';
import { CurrencyWithoutDecimalsPipe } from '../../pipes/currency-without-decimals.pipe';

import { VisitDate } from 'src/app/core/models/client.model';
import { MinOrder } from 'src/app/core/services/min-amount/interface/min-order.interface';

import { MinOrderService } from 'src/app/core/services/min-amount/min-order.service';

import { env } from 'src/app/app.component';

@Component({
  selector: 'app-min-order-label',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyWithoutDecimalsPipe,
    BoxesPipe,
    TranslateModule
  ],
  templateUrl: './min-order-label.component.html',
  styleUrl: './min-order-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinOrderLabelComponent {
  private minOrderService = inject(MinOrderService);

  private config = env.getConfigByCountry();
  showMinAmountByDates = this.config?.showMinAmountByDates ?? false;
  showGreenTextMinAmount = this.config?.showGreenTextMinAmount ?? false;

  minOrder: MinOrder | null;
  @Input() set date(value: VisitDate | null) {
    if (value) {
      this.minOrder = this.minOrderService.getMinOrderByDate(value) ?? null;
    }
  }
}

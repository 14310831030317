import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EventSelectDateBtn } from 'src/app/shared/components/select-delivery-date/enums/select-delivery-dates.enum';

import { ModalDesktopFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';
import { ModalMobileFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-mobile-frozen-delivery-date.service';
import { IsMobileService } from '../is-mobile/is-mobile.service';


@Injectable({
  providedIn: 'root'
})
export class ShowModalFrozenDeliveryDateService {
  private modalDesktopFrozenDeliveryDateService = inject(ModalDesktopFrozenDeliveryDateService);
  private modalMobileFrozenDeliveryDateService = inject(ModalMobileFrozenDeliveryDateService);
  private isMobileService = inject(IsMobileService);

  private get isMobile(): boolean {
    return this.isMobileService.isMobile;
  }

  openModal$(): Observable<EventSelectDateBtn> {
    if (this.isMobile) {
      if (this.modalMobileFrozenDeliveryDateService.currentShowModal) {
        this.modalMobileFrozenDeliveryDateService.closeModal(EventSelectDateBtn.CLOSE_BTN)
      }

      this.modalMobileFrozenDeliveryDateService.openModal();
      return this.modalMobileFrozenDeliveryDateService.isCloseModal$;
    }

    if (this.modalDesktopFrozenDeliveryDateService.modalRef) {
      this.modalDesktopFrozenDeliveryDateService.closeModal(EventSelectDateBtn.CLOSE_BTN);
    }
    return this.modalDesktopFrozenDeliveryDateService.openModal().closed;
  }

  enableBackdrop(): void {
    if (this.isMobile) {
      this.modalMobileFrozenDeliveryDateService.canCloseWithShadow = true;
      return;
    }

    this.modalDesktopFrozenDeliveryDateService.backdrop = true;
  }

  disableBackdrop(): void {
    if (this.isMobile) {
      this.modalMobileFrozenDeliveryDateService.canCloseWithShadow = false;
      return;
    }

    this.modalDesktopFrozenDeliveryDateService.backdrop = 'static';
  }
}

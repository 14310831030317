import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PrincipalFilterIds } from 'src/app/core/enums/principal-filter-ids.enum';
import { ProductTypes } from 'src/app/core/enums/product-types';
import { Client } from 'src/app/core/models/client.model';
import { ProductsService } from 'src/app/core/services/products.service';
import * as ClientActions from 'src/app/core/state/actions/client.actions';

@Component({
  selector: 'app-ka-discount-carousel',
  templateUrl: './ka-discount-carousel.component.html',
  styleUrls: ['./ka-discount-carousel.component.scss'],
})
export class KaDiscountCarouselComponent {
  readonly ROOT_LANG = 'HOME.DISCOUNT_CAROUSEL';
  discounts: any;
  selectedClient: Client;
  private subscriptions = new Subscription();

  constructor(
    private store: Store<{ client: Client }>,
    private productsService: ProductsService,
    private router: Router,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
        if (!client.discountProductsforOffert)
          this.store.dispatch(
            ClientActions.loadDiscountProductsForOffert({ isDiscount: true }),
          );
        this.discounts = client.discountProductsforOffert.sort(this.compareImage);
        if (
          this.discounts &&
          this.discounts?.length > 0 &&
          this.discounts?.length > 9
        ) {
          this.discounts = this.discounts.slice(0, 10);
        }
      }),
    );
  }

  redirect(idStep: number) {
    const route = this.router.url.includes('mcc')
      ? '/mcc/' + idStep + '/'
      : '/main/nuevo-pedido/' + idStep + '/';
    const filter = PrincipalFilterIds.Discounts;
    this.router.navigate([route], {
      queryParams: { grupo: ProductTypes.UNIT, filter },
    });
  }

  private compareImage(a, b) {
    // Función auxiliar para verificar si image contiene "default_image"
    const contieneDefault = (obj) => obj.image.includes('discount_default.png');

    // Verificar si ambos contienen "default_image"
    const aContieneDefault = contieneDefault(a);
    const bContieneDefault = contieneDefault(b);

    // Ordenar lógica basada en la presencia de "default_image"
    if (aContieneDefault && !bContieneDefault) {
      return 1; // a va después de b
    } else if (!aContieneDefault && bContieneDefault) {
      return -1; // a va antes de b
    } else {
      return 0; // mantener el orden
    }
  }
}

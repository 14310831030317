import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, scan } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SpinnerService {
  private requestChanges = new Subject<number>();
  public requests$ = this.requestChanges.pipe(scan((acc, change) => Math.max(0, acc + change), 0));

  constructor(private spinner: NgxSpinnerService) {
    
    this.requests$.subscribe(count => {
      if (count === 1) {
        this.spinner.show();
      } else if (count === 0) {
        this.spinner.hide();
      }
    });
  }

  showSpinner(): void {
    this.requestChanges.next(1);
  }

  hideSpinner(): void {
    this.requestChanges.next(-1);
  }
}
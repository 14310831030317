import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { DateTime } from 'luxon';

import { getCartInvoiceDeadline } from '../../state/selectors/cart.selectors';

export interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeadlineService {
  private store = inject(Store);
  
  private get deadline$(): Observable<DateTime> {
    return this.store.select(getCartInvoiceDeadline).pipe(
      map(deadline => DateTime.fromISO(deadline))
    );
  }

  calculateMinutesLeft$(): Observable<number> {
    return this.deadline$.pipe(
      map((parsedDeadline) => {
        const now = DateTime.utc();
        const minutesLeft = parsedDeadline.diff(now, 'minutes').minutes;
        return minutesLeft > 0 ? Math.floor(minutesLeft) : 0;
      })
    );
  }

  calculateTimeLeft$(): Observable<TimeLeft> {
    return this.deadline$.pipe(
      map((parsedDeadline) => {
        const now = DateTime.utc();
        const timeDifference = parsedDeadline.diff(now, ['hours', 'minutes', 'seconds']).toObject();

        if (
          (timeDifference.hours ?? 0) < 0 ||
          (timeDifference.minutes ?? 0) < 0 ||
          (timeDifference.seconds ?? 0) < 0
        ) {
          return { hours: 0, minutes: 0, seconds: 0 };
        }

        return {
          hours: Math.floor(timeDifference.hours ?? 0),
          minutes: Math.floor(timeDifference.minutes ?? 0),
          seconds: Math.floor(timeDifference.seconds ?? 0),
        };
      })
    );
  }
}

@if((client$ | async); as client) {
  <radio-group [formControl]="control">
    @if(visitDates?.length) {
      <div class="select-date-options-container">
        <div class="radio-container">
          <ng-container *ngFor="let date of visitDates; trackBy: trackByDates">
            <div class="radio-option">
              <ng-container *ngTemplateOutlet="datesTemplate; context: { date: date }"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    }

    <ng-template #datesTemplate let-date="date">
      <div class="radio-option-info">
        <app-radio [value]="date">
          {{date.visitDate | weekdayDayMonthParser}}
        </app-radio>
      </div>
    
      <app-min-order-label [date]="date"></app-min-order-label>
    </ng-template>
  </radio-group>
}

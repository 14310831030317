import { inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DraftRequestModalComponent } from 'src/app/shared/modals/draft-request-modal/draft-request-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ShowModalDraftProductsService {
  private ngbModalService = inject(NgbModal);
  modalRef: NgbModalRef;

  openModal(): NgbModalRef {
    if (this.modalRef) {
      this.closeModal();  
    }

    this.modalRef = this.ngbModalService.open(DraftRequestModalComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'ngbmodal-centered',
    });
    return this.modalRef;
  }

  closeModal(): void {
    this.modalRef.close();
  }
}

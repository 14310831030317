import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SkeletonModule } from './skeleton/skeleton.module';

import { TitleCaseFirstWordPipe } from '../core/pipes/title-case-first-word.pipe';

import { ButtonComponent } from './button/button.component';
import { ListComponent } from './list/list.component';
import { StepByStepComponent } from './step-by-step/step-by-step.component';
import { TitlePageComponent } from './title-page/title-page.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { CardComponent } from './card/card.component';
import { LabelComponent } from './label/label.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { AlertComponent } from './alert/alert.component';
import { InputComponent } from './input/input.component';
import { ModalMobileComponent } from './modal-mobile/modal-mobile.component';

const components = [
  ListComponent,
  StepByStepComponent,
  TitlePageComponent,
  TextAreaComponent,
  LabelComponent,
  ProgressBarComponent,
  AlertComponent,
  InputComponent,
  ModalMobileComponent
];

const pipes = [
  TitleCaseFirstWordPipe
];

@NgModule({
  declarations: [components],
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    SkeletonModule, 
    pipes,
    ButtonComponent,
  ],
  exports: [components, SkeletonModule],
})
export class ComponentsModule {}

import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';

import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { ModalDesktopFrozenDeliveryDateService } from '../select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';
import { ModalMobileFrozenDeliveryDateService } from '../select-frozen-delivery-date/services/modal-mobile-frozen-delivery-date.service';
import { ShowModalDeliveryDateService } from 'src/app/core/services/show-modal-ambient-delivery-date/show-modal-ambient-delivery-date.service';
import { VisitDatesService } from 'src/app/core/services/visit-date.service';
import { DeadlineService } from 'src/app/core/services/deadline/deadline.service';
import { ShowModalFrozenDeliveryDateService } from 'src/app/core/services/show-modal-frozen-delivery-date/show-modal-frozen-delivery-date.service';

import { hasCartDeliveryFrozenProducts, hasCartDeliveryNotFrozenProducts } from 'src/app/core/state/selectors/cart.selectors';

@Component({
  selector: 'app-exp-timer-warn',
  templateUrl: './exp-timer-warn.component.html',
  styleUrls: ['./exp-timer-warn.component.scss'],
})
export class ExpTimerWarnComponent implements OnInit, OnDestroy {
  private showModalDeliveryDateService = inject(ShowModalDeliveryDateService);
  private showModalFrozenDeliveryDateService = inject(ShowModalFrozenDeliveryDateService);
  private deadlineService = inject(DeadlineService);

  cart: Cart;
  client: Client;
  user: UserInfo;
  private subscriptions = new Subscription();
  timeRemaining: any;
  timeRemainingInterval;
  isChileanUser: boolean;
  cartHasDeliveryFrozenProducts?: boolean;
  cartHasDeliveryNotFrozenProducts?: boolean;
  readonly ROOT = 'NEW_ORDER.RIGHT_SIDEBAR.';
  readonly CountryCodes = CountryCodes;
  get isMobile(): boolean {
    return this._isMobileService.isMobile;
  }
  @Input() small: boolean;

  constructor(
    private store: Store<{ cart: Cart; client: Client; user: UserInfo }>,
    private _isMobileService: IsMobileService,
    private _visitDateService: VisitDatesService,
    private modalMobileFrozenDeliveryDateService: ModalMobileFrozenDeliveryDateService,
    private modalDesktopFrozenDeliveryDateService: ModalDesktopFrozenDeliveryDateService,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(this.store.select(hasCartDeliveryFrozenProducts).subscribe((hasDeliveryFrozenProducts) => (this.cartHasDeliveryFrozenProducts = hasDeliveryFrozenProducts)));
    this.subscriptions.add(this.store.select(hasCartDeliveryNotFrozenProducts).subscribe((hasDeliveryNotFrozenProducts) => (this.cartHasDeliveryNotFrozenProducts = hasDeliveryNotFrozenProducts)));
  }

  ngOnInit(): void {
    this.timeRemainingInterval = setInterval(() => {
      this.getTimeRemaining();
    }, 1000);
    this.isChileanUser = this.user.countryId === this.CountryCodes.CHILE;
  }

  getTimeRemaining(): void {
    if (!this._visitDateService.isFirstDeliveryDate()) {
      this.timeRemaining = undefined;
      return;
    }

    this.subscriptions.add(
      this.deadlineService.calculateTimeLeft$()
        .pipe(take(1))
        .subscribe({
          next: (timeLeft) => {
            this.timeRemaining = {
              hours: String(timeLeft.hours).padStart(2, '0'),
              minutes: String(timeLeft.minutes).padStart(2, '0'),
              seconds: String(timeLeft.seconds).padStart(2, '0'),
            };
          }
        })
    );
  }

  openSelectFrozenDeliveryDateModal(): void {
    this.showModalFrozenDeliveryDateService.openModal$()
      .pipe(take(1))
      .subscribe();
  }

  openSelectDeliveryDateModal(): void {
    this.showModalDeliveryDateService.openModal$()
      .pipe(take(1))
      .subscribe();
  }

  ngOnDestroy(): void {
    clearInterval(this.timeRemainingInterval);
    this.subscriptions.unsubscribe();
  }
}
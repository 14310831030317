import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Pipe({
  standalone: true,
  name: 'currencyWithoutDecimals',
  pure: true,
})
export class CurrencyWithoutDecimalsPipe implements PipeTransform {
  constructor(private userService: UserService) { }

  transform(value: string | number): string {
    return this.userService.getCurrencyWithoutDecimalsByCountry(value);
  }
}

<div class="delivery-details card-sidebar">
  <h3 class="title-h3">{{ 'NEW_ORDER.RIGHT_SIDEBAR.TITLE_INFORMATION' | translate }}</h3>
  <div *ngIf="!showFrozenDates" class="d-flex flex-row align-items-center justify-content-between mb-3">
    <svg>
      <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-calendar-event" />
    </svg>
    <div>
      <p class="mid-strong">{{ ROOT_LANG + 'DELIVERY_DATE' | translate }}:</p>
      <p>{{ cart.visitDate | kaDate }}</p>
    </div>
  </div>

  <div *ngIf="showFrozenDates" class="mb-3">
    <div class="d-flex flex-row align-items-center justify-content-between">
      <svg>
        <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-calendar-event" />
      </svg>

      <div class="d-flex flex-column align-items-start">
        <p class="mid-strong">{{ ROOT_LANG + 'DELIVERY_DATES' | translate }}:</p>

        <div class="d-flex flex-column align-items-start">
          <div *ngIf="cartHasDeliveryNotFrozenProducts" class="mb-2">
            <p>
              <span class="">{{ ROOT_LANG + 'DELIVERY_DATE_NOT_FROZEN' | translate }}:</span> {{ cart.visitDate | kaDate }}
            </p>
          </div>

          <div *ngIf="cartHasDeliveryFrozenProducts" class="mb-2">
            <p>
              <span class="">{{ ROOT_LANG + 'DELIVERY_DATE_FROZEN' | translate }}:</span> {{ cart.frozenVisitDate | kaDate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row align-items-center justify-content-between mb-3">
    <svg>
      <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-map-pin" />
    </svg>
    <div>
      <p class="mid-strong">{{ ROOT_LANG + 'DELIVERY_ADDRESS' | translate }}:</p>
      <p>{{ client.street }} {{ client.doorNumber }}</p>
    </div>
  </div>

  <div *ngIf="(step === 6 || step === 5) && (user.countryId !== 'AR' && cart.paymentMethod)" class="d-flex">
    <svg>
      <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-credit-card" />
    </svg>
    <div *ngIf="!(isMultiplePaymentMethodAvailable && 
          cart.multiplePaymentMethodsBySegment?.length > 0)
    ">
      <p class="mid-strong">{{ ROOT_LANG + 'PAYMENT_METHOD.LABEL' | translate }}:</p>
      <p>{{ cart.paymentMethod?.name || ROOT_LANG_1 + 'PAYMENT_METHOD_INFO.NO_PAYMENT_METHOD_AVAILABLE' | translate }}</p>
    </div>
    <div *ngIf="isMultiplePaymentMethodAvailable && 
          cart.multiplePaymentMethodsBySegment?.length > 0
    ">
      <ng-container *ngIf="isMultiplePaymentMethodAvailable && 
          cart.multiplePaymentMethodsBySegment?.length > 0">
      <p class="mid-strong">{{ ROOT_LANG + 'PAYMENT_METHOD.LABEL' | translate }}:</p>
        <ng-container 
        *ngFor=" let paymentMethodBySegement of accumulatedPaymentMethods"
        > 
        <div class="d-flex justify-content-between">
          <p>{{ paymentMethodBySegement?.paymentMethod.name }}</p>
          <p>{{paymentMethodBySegement?.spent | kaCurrency}}</p>
        </div>   
        </ng-container>
    </ng-container>
    </div>
  </div>
</div>
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Observable, Subscription, throwError } from 'rxjs';
import { LoadingModalComponent } from 'src/app/shared/modals/loading-modal/loading-modal.component';
import { environment } from '../../../environments/environment';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { UserInfo } from '../models/user-info.model';
import { UserLocal } from '../models/user-local.model';
import { Client } from '../models/client.model';
import { PassAttempModalComponent } from 'src/app/shared/modals/pass-attemp-modal/pass-attemp-modal.component';
import * as ClientActions from 'src/app/core/state/actions/client.actions';
import { ModalsService } from './modals.service';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements OnDestroy {
  BASE_URLS = {
    serverUrlPublic: environment.BASE_URL_PUBLIC,
    serverUrlInternal: environment.BASE_URL_INTERNAL,
    serverUrlIntegrations: environment.BASE_URL_INTEGRATIONS,
  };
  user: UserInfo;
  userLocal: UserLocal;
  client: Client;
  private subscriptions = new Subscription();
  requestsCounter = 0;

  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private modalsService: ModalsService,
    private store: Store<{
      user: UserInfo;
      userLocal: UserLocal;
      client: Client;
    }>,
    private gtmService: GoogleTagManagerService,
    private loadingScreenService: LoadingScreenService,
    private spinnerService: SpinnerService,
  ) {
    this.subscriptions.add(
      this.store
        .select('userLocal')
        .subscribe((userLocal) => (this.userLocal = userLocal)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
  }

  get(
    endpoint: string,
    endpointCode: EndpointsCodes,
    {
      targetApi = 'serverUrlPublic',
      customUrl = '',
      ngxSpinner = true,
      customSpinner = false,
      showError = true,
      customSpinnerMsg = 'LOADINGS.GET_DISCOUNTS',
    },
  ): Observable<any> {
    const finalUrl = this.parseUrl(endpoint, targetApi, customUrl);

    if (ngxSpinner && !customSpinner) this.spinnerService.showSpinner();

    let customLoading: NgbModalRef;
    if (customSpinner && !this.modalService.hasOpenModals()) {
      customLoading = this.modalService.open(LoadingModalComponent, {
        windowClass: 'ngbmodal-centered',
        size: 'lg',
      });
      customLoading.componentInstance.msg = customSpinnerMsg;
    }
  
    return new Observable((obs) => {
      this.httpClient.get(finalUrl).subscribe(
        (res) => {
          if (ngxSpinner && !customSpinner) this.spinnerService.hideSpinner();
          if (customSpinner && customLoading) customLoading.close();
          obs.next(res);
        },
        (error: HttpErrorResponse) => {
          if (ngxSpinner && !customSpinner) this.spinnerService.hideSpinner();
          if (customSpinner && customLoading) customLoading.close();
          this.handleApiError(error, endpointCode, showError);
          obs.error(error.error);
          obs.complete();
        },
      );
    });
  }

  post(
    endpoint: string,
    endpointCode: EndpointsCodes,
    body: object,
    {
      targetApi = 'serverUrlPublic',
      customUrl = '',
      ngxSpinner = true,
      showError = true,
      showLoadingScreen = false,
    },    
  ): Observable<any> {
    const finalUrl = this.parseUrl(endpoint, targetApi, customUrl);
    
    if (showLoadingScreen) {
      this.loadingScreenService.show();
    } else if (ngxSpinner) {
      this.spinnerService.showSpinner();
    }

    return new Observable((obs) => {
      this.httpClient.post(finalUrl, body).subscribe(
        (res) => {
          if (showLoadingScreen) {
            this.loadingScreenService.hide();
          } else if (ngxSpinner) {
            this.spinnerService.hideSpinner();
          }

          obs.next(res);
          obs.complete();
        },
        (error: HttpErrorResponse) => {
          if (showLoadingScreen) {
            this.loadingScreenService.hide();
          } else if (ngxSpinner) {
            this.spinnerService.hideSpinner();
          }

          this.handleApiError(error, endpointCode, showError);
          obs.error(error.error);
          obs.complete();
        },
      );
    });
  }

  put(
    endpoint: string,
    endpointCode: EndpointsCodes,
    body: object,
    {
      targetApi = 'serverUrlPublic',
      customUrl = '',
      ngxSpinner = true,
      showError = true,
    },
  ): Observable<any> {
    const finalUrl = this.parseUrl(endpoint, targetApi, customUrl);

    if (ngxSpinner) this.spinnerService.showSpinner();

    return new Observable((obs) => {
      this.httpClient.put(finalUrl, body).subscribe(
        (res) => {
          if (ngxSpinner) this.spinnerService.hideSpinner();
          obs.next(res);
          obs.complete();
        },
        (error: HttpErrorResponse) => {
          if (ngxSpinner) this.spinnerService.hideSpinner();
          this.handleApiError(error, endpointCode, showError);
          obs.error(error.error);
          obs.complete();
        },
      );
    });
  }

  delete(
    endpoint: string,
    endpointCode: EndpointsCodes,
    body: object,
    {
      targetApi = 'serverUrlPublic',
      customUrl = '',
      ngxSpinner = true,
      showError = true,
    },
  ): Observable<any> {
    const finalUrl = this.parseUrl(endpoint, targetApi, customUrl);

    if (ngxSpinner) this.spinnerService.showSpinner();

    return new Observable((obs) => {
      this.httpClient
        .request('DELETE', finalUrl, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body,
        })
        .subscribe(
          (res) => {
            if (ngxSpinner) this.spinnerService.hideSpinner();
            obs.next(res);
            obs.complete();
          },
          (error: HttpErrorResponse) => {
            if (ngxSpinner) this.spinnerService.hideSpinner();
            this.handleApiError(error, endpointCode, showError);
            obs.error(error.error);
            obs.complete();
          },
        );
    });
  }

  parseUrl(endpoint, targetApi = 'serverUrlPublic', customUrl): string {
    if (customUrl) {
      return customUrl;
    }
    const finalUrl = this.BASE_URLS[targetApi];
    const countryId =
      this.user?.countryId || this.userLocal?.geoCountryCode || '';
    return finalUrl + countryId + '/' + endpoint;
  }

  handleOrderError(): Observable<void> {
    return new Observable<void>((obs) => {
      obs.next();
    });
  }

  private handleApiError(
    error: HttpErrorResponse,
    endpointCode: EndpointsCodes,
    showError: boolean,
  ): void {
    if (error.status === 400) {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
      if (
        error.error?.message === 'clientLock_client_is_lock' ||
        error.error.message === 'clientLock_maintenance'
      ) {
        this.store.dispatch(
          ClientActions.setClientHasLockOrder({ hasLockOrder: true }),
        );
        this.store.dispatch(
          ClientActions.updateHasCreditLock({ hasCreditLock: true }),
        );
      }

      if (error.error?.message === 'clientLock_maintenance') {
        this.modalsService.openMaintenanceModal();
        this.store.dispatch(
          ClientActions.setLockType({ lockType: 'Maintenance' }),
        );
        return;
      }

      if (error.error?.message === 'clientLock_client_is_lock') {
        this.modalsService.openClientOrderBlockedModal();
        this.store.dispatch(
          ClientActions.setLockType({ lockType: 'ClientLock' }),
        );
        this.store.dispatch(
          ClientActions.updateHasCreditLock({ hasCreditLock: true }),
        );
        return;
      }
    }

    if (error.error?.errorType === 'LimitExceededException') {
      this.modalService.open(PassAttempModalComponent, {
        windowClass: 'ngbmodal-centered',
      });
      return;
    }

    if (
      error.status === 404 &&
      (endpointCode === EndpointsCodes.GET_ORDERS_HISTORY ||
        endpointCode === EndpointsCodes.GET_DRAFT_ORDER ||
        endpointCode === EndpointsCodes.GET_SUGGESTED_PROD ||
        endpointCode === EndpointsCodes.POST_ORDER)
    ) {
      return;
    }

    if (showError) {
      if (endpointCode === EndpointsCodes.UPDATE_USER_PWD)
        error.error.code = error.error.errorType;
      this.toastError(error.error?.code, endpointCode);
    } else {
      this.pushGTMError(endpointCode);
    }
  }

  private toastError(errorCode, endpointCode: EndpointsCodes): void {
    this.translateService
      .get(['ERRORS.' + errorCode, 'ERRORS.' + endpointCode])
      .subscribe((errors: any) => {
        const beErrorMsj = errors[Object.keys(errors)[0]];
        const feErrorMsj = errors[Object.keys(errors)[1]];
        this.pushGTMError(endpointCode, feErrorMsj);
        if (!beErrorMsj.startsWith('ERRORS.')) {
          this.toastr.error(beErrorMsj);
          return;
        }
        if (!feErrorMsj.startsWith('ERRORS.')) {
          this.toastr.error(feErrorMsj);
          return;
        }
        this.toastr.error(
          this.translateService.instant('ERRORS.UNKNOWN_ERROR'),
        );
      });
  }

  pushGTMError(endpointCode: EndpointsCodes, feErrorMsj?: string): void {
    this.gtmService.pushTag({
      event: 'error',
      error: {
        serviceKey: endpointCode,
        source: 'backend',
        description: feErrorMsj || '',
      },
    });
  }

  // FIXME usar el parametro queryParams de httpClient.get() en vez de armarlos a mano
  generateQueryParams(params: object): string {
    let queryParams = '?';
    for (const param in params) {
      if (
        params.hasOwnProperty(param) &&
        (params[param] || params[param] === 0 || params[param] === false)
      ) {
        const value = params[param];
        queryParams += param + '=' + value + '&';
      }
    }
    return queryParams;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

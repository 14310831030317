import { Injectable } from '@angular/core';

import { MinOrder } from './interface/min-order.interface';
import { VisitDate } from '../../models/client.model';

import { createMinOrder } from './helper/min-order.helper';

@Injectable({
  providedIn: 'root'
})
export class MinOrderService {
  getMinOrderByDate(date: VisitDate): MinOrder {
    return createMinOrder(date);
  }
}

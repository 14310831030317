<div class="container" [class]="isMobile ? 'mobile' : 'desktop ka-sidebar'">
  <img
    class="logoAndina"
    [src]="
      user.countryId === CountryCodes.CHILE
        ? ('./assets/media/logo_miandina.webp' | toThumbnail: true) 
        : ('./assets/media/logo_mcc.webp' | toThumbnail: true)
    "
    [alt]="
      user.countryId === CountryCodes.CHILE
        ? 'Logo Mi Andina'
        : 'Logo Mi Coca-Cola'
    "
  />

  <ul class="options">
    <ng-template 
      ngFor 
      let-option 
      [ngForOf]="sidebarOptions" 
      let-index="index"
      >
      <li
        *ngIf="option.show && !option.options;"
        id="{{'itemSubmenu-' + index}}"
        class="li-sidebar"
        [ngClass]="{ active: option.active, disabled: option.disabled }"
        (click)="optionSelected(option)"
      >
        <svg class="img-fluid mr-8 icon-ka-sidebar" stroke-width="3.25">
          <use
            [attr.xlink:href]="
              './assets/icons/tabler-sprite.svg#tabler-' + option.icon
            "
          />
        </svg>
        <span>{{ 'HOME.' + option.langKey | translate }}</span>
      </li>

      <ng-container *ngIf="option.show && option.options;">
        <li
          class="li-sidebar"
          id="{{'itemSubmenuMain-' + index}}"
          [ngClass]="{ active: option.active, disabled: option.disabled }"
          (click)="openCloseSubMenu(option)"
          >
          <svg class="img-fluid mr-8 icon-ka-sidebar" stroke-width="3.25">
            <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-' + option.icon"/>
          </svg>

          <span>{{ 'HOME.' + option.langKey | translate }}</span>
  
          <svg 
            class="submenu-icon-main {{option?.open ? 'open' : null}}"
            stroke-width="3.25">
            <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-chevron-down'"/>
          </svg>
        </li>
        <ng-container *ngIf="option?.open">
          <li 
            *ngFor="let item of option.options"  
            class="subItemMenu"
            >
            <svg class="mr-8">
              <use attr.xlink:href="{{'./assets/icons/tabler-sprite.svg#' + item.icon }}"/>
            </svg>
            <a (click)="goToIframe(item.url)">{{ item.label }}</a>
          </li>
        </ng-container>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="!isMobile">
      <li
        *ngIf="!client.hasLockOrder"
        [ngStyle]="{ visibility: isNewOrderSelected ? 'hidden' : 'visible' }"
      >
        <app-show-delivery-date></app-show-delivery-date>
      </li>
      <li
        *ngIf="!client.hasLockOrder"
        [ngStyle]="{
          visibility:
            isNewOrderSelected || !isFirstDeliveryDate() ? 'hidden' : 'visible'
        }"
        class="deadline d-flex flex-column py-1"
      >
        {{ 'HOME.ORDER_DELIVERY_MESSAGE' | translate }}
        {{ DateUtility.parseDateToHHMM(cart.invoiceDeadline) }} hs.
        <span *ngIf="!isDeadlineToday()"
          >del día {{ cart.invoiceDeadline | kaDate: 'day mm/dd' }}</span
        >
      </li>
    </ng-container>
  </ul>

  <div *ngIf="!isMobile" class="w-100 ps-4">
    <p
      class="privacy-terms mb-3 ms-3 pointer mid-strong"
      (click)="openTyCModal()"
    >
      {{ 'LOGIN.HOME.LEGALS' | translate }}
    </p>
    <button
      (click)="signOut()"
      class="btn-transparent border-0 d-flex flex-row align-items-center"
      id="sign-out-desktop"
    >
      <svg>
        <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-logout" />
      </svg>
      <span class="ms-1 mid-strong">{{ 'HOME.SIGN_OUT' | translate }}</span>
    </button>
    <div class="w-100 pe-3">
      <app-ka-frontend-version></app-ka-frontend-version>
    </div>
  </div>
</div>

<app-ka-draggable-carousel *ngIf="!isExternalUser">
  <ng-container *ngIf="categories">
    <ng-container *ngIf="categoryButtonCarousel">
      <app-ka-categories-button
        *ngIf="currentCategory?.name"
        [text]="currentCategory?.name"
        [image]="
          IMG_ROOT +
          'categories/' +
          user.countryId +
          '/' +
          getCategoryImageName(currentCategory?.id) +
          '.png'
        "
        (clickEvent)="goToCategory($event, page !== 'home')"
        [selected]="page !== 'home'"
      ></app-ka-categories-button>
      <ng-container *ngFor="let category of categories; index">
        <app-ka-categories-button
          *ngIf="currentCategory.name !== category.name"
          [text]="category.name"
          [image]="
            IMG_ROOT +
            'categories/' +
            user.countryId +
            '/' +
            getCategoryImageName(category.id) +
            '.png'
          "
          [alt]="'Imagen de ' + category.name"
          (clickEvent)="
            goToCategory(
              $event,
              category,
              currentCategory.name === category.name && page !== 'home'
            )
          "
          [selected]="currentCategory.id === category.id && page !== 'home'"
        ></app-ka-categories-button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="categoryIconCarousel">
      <app-ka-categories-icon
        *ngIf="currentCategory?.name"
        [text]="currentCategory?.name"
        [image]="
          IMG_ROOT +
          'categories/' +
          user.countryId +
          '/icons/' +
          getCategoryImageName(currentCategory?.name) +
          '.png' | toThumbnail
        "
        (clickEvent)="goToCategory($event, currentCategory?.name, page !== 'home')"
        [selected]="page !== 'home'"
      ></app-ka-categories-icon>
      <app-ka-categories-icon
        *ngIf="hasSuggestedProducts"
        [category]="PrincipalFilterIds.Suggested"
        [text]="ROOT_LANG + 'SUGGESTED' | translate"
        [image]="
          IMG_ROOT + 'categories/' + user.countryId + '/icons/sugeridos.png' | toThumbnail
        "
        (clickEvent)="goToCategorySuggested('2')"
      ></app-ka-categories-icon>
      <ng-container *ngFor="let category of categories; index">
        <app-ka-categories-icon
          *ngIf="currentCategory.name !== category.name && category.id !== PrincipalFilterIds.Suggested"
          [text]="category.name"
          [image]="
            IMG_ROOT +
            'categories/' +
            user.countryId +
            '/icons/' +
            getCategoryImageName(category.name) +
            '.png' | toThumbnail
          "
          [alt]="'Imagen de ' + category.name"
          (clickEvent)="
            goToCategory(
              $event,
              category,
              currentCategory.name === category.name && page !== 'home'
            )
          "
          [selected]="currentCategory.name === category.name && page !== 'home'"
        ></app-ka-categories-icon>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!categories">
    <ng-container *ngFor="let skeleton of skeletonDummy">
      <app-ka-skeleton-category-button
        class="m-3"
      ></app-ka-skeleton-category-button>
    </ng-container>
  </ng-container>
</app-ka-draggable-carousel>

<ng-container *ngIf="productFilter">
  <div class="menu-filter-clicked d-flex flex-column flex-lg-row align-items-center justify-content-start w-100">
    <div class="w-100 d-flex d-lg-none flex-column flex-sm-row align-items-center justify-content-between pb-4">
      <button
        class="w-100 menu-filter-clicked filter-discount-returnability-btn d-flex align-items-center justify-content-center mr-0 mr-sm-2 mb-3 mb-sm-0 pr-3 pl-3"
        [ngClass]="{ 'filter-active': productFilter['isDiscount'] }"
        (click)="updateSpecificFilter('isDiscount')">
        <svg class="menu-filter-clicked mr-1">
          <use
            class="open-menu-svg"
            xlink:href="./assets/icons/tabler-sprite.svg#tabler-discount"
          />
        </svg>
        {{ ROOT_LANG_1 + 'FILTER.PROMOTIONS' | translate }}
      </button>
      <button
        class="w-100 menu-filter-clicked filter-discount-returnability-btn d-flex align-items-center justify-content-center ml-0 ml-sm-2 pr-3 pl-3"
        [ngClass]="{ 'filter-active': productFilter['returnability'] }"
        (click)="updateSpecificFilter('returnability')">
        <svg class="menu-filter-clicked">
          <use
            class="open-menu-svg"
            xlink:href="./assets/icons/tabler-sprite.svg#tabler-bottle"
          />
        </svg>
        {{ ROOT_LANG_1 + 'FILTER.RETURNABLE' | translate }}
      </button>
      <button
        class="w-100 menu-filter-clicked filter-discount-returnability-btn d-flex align-items-center justify-content-center ml-0 ml-sm-2 pr-3 pl-3 mt-3"
        [ngClass]="{ 'filter-active': productFilter['isFavorite'] }"
        (click)="updateSpecificFilter('isFavorite')">
          <img
            class="menu-filter-clicked mr-1 fav-icon open-menu-svg"
            src="./assets/icons/empty-heart{{ heartImage ? '-white' : '' }}.svg" alt="SVG">
          {{ ROOT_LANG_1 + 'FILTER.FAVORITES' | translate }}
      </button>
    </div>
    <div class="mb-3 mb-lg-0 field">
      <app-ka-dropdown
        class="w-100 w-lg-auto"
        [filter]="category"
        [selected]="productFilter[category.key]"
        (selectOption)="updateFilters(category.key, $event)">
      </app-ka-dropdown>
    </div>
    <div
      *ngFor="let filter of filters | keyvalue"
      class="mb-3 mb-lg-0 field">
      <app-ka-dropdown
        class="w-100 w-lg-auto"
        [filter]="filter"
        [selected]="productFilter[filter.key]"
        (selectOption)="updateFilters(filter.key, $event)">
      </app-ka-dropdown>
    </div>
    <div
      class="menu-filter-clicked clean blue-link align-self-center d-flex flex-row align-items-center justify-content-center"
      (click)="cleanFilters()">
      <svg class="svg-img mr-2 trash">
        <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-trash" />
      </svg>
      <span class="filters-clean menu-filter-clicked">{{ ROOT_LANG + 'CLEAN_FILTERS' | translate }}</span>
    </div>
  </div>
</ng-container>

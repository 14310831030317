@if ((minOrderAmbient$ | async); as minOrderAmbient) {
  @if(cartHasDeliveryNotFrozenProducts && minOrderAmbient.value !== 0) {
    @if (!minOrderAmbient.isByQuantity) {
      <ng-container *ngTemplateOutlet="byAmount; context: { minOrder: minOrderAmbient }"></ng-container>
    }
    
    @if(minOrderAmbient.isByQuantity) {
      <ng-container *ngTemplateOutlet="byQuantity; context: { minOrder: minOrderAmbient }"></ng-container>
    }
  }
}

@if((minOrderFrozen$ | async); as minOrderFrozen) {
  @if(cartHasDeliveryFrozenProducts && minOrderFrozen.value !== 0) {
    @if(!minOrderFrozen.isByQuantity) {
      <ng-container *ngTemplateOutlet="byAmount; context: { minOrder: minOrderFrozen }"></ng-container>
    }

    @if(minOrderFrozen.isByQuantity) {
      <ng-container *ngTemplateOutlet="byQuantity; context: { minOrder: minOrderFrozen }"></ng-container>
    }
  }
}

<ng-template #byAmount let-minOrder="minOrder">
  <div class="d-none d-lg-flex ka-grey-card">
    <div class="item">
      <div class="image red-circle">
        @if(minOrder.isFrozen) {
          <img class="w-100" alt="CONGELADOS" src="{{ '../../../../../assets/media/icons/CONGELADOS.webp' | toThumbnail:true }}" />
        } @else {
          <svg class="mx-auto tabler-bucket">
            <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-bucket" />
          </svg>
        }
      </div>
      <div class="content">
        <div class="text">
          <span>
            {{ (minOrder.isFrozen ? ROOT + 'MINIMUM_PURCHASE_FROZEN' : ROOT + 'MINIMUM_PURCHASE') | translate }}
          </span>
          <span>{{ minOrder.value | kaCurrency }}</span>
        </div>
        <p class="my-2">
          <ngb-progressbar 
            [type]="
              minOrder.isFrozen 
                ? percentAmountFrozen >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED 
                : percentAmount >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED
              " 
            [value]="
              minOrder.isFrozen
              ? percentAmountFrozen
              : percentAmount
            ">
          </ngb-progressbar>
        </p>
      </div>
    </div>
  </div>
  
  <div class="d-flex d-lg-none mobile">
    <div class="item">
      <div class="content">
        <div class="text">
          <span>
            {{ (minOrder.isFrozen ? ROOT + 'MINIMUM_PURCHASE_FROZEN' : ROOT + 'MINIMUM_PURCHASE') | translate }}
          </span>
          <span>{{ minOrder.value | kaCurrency }}</span>
        </div>
        <p class="my-2">
          <ngb-progressbar 
            [type]="
              minOrder.isFrozen 
                ? percentAmountFrozen >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED 
                : percentAmount >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED
              " 
            [value]="
              minOrder.isFrozen
              ? percentAmountFrozen
              : percentAmount
            ">
          </ngb-progressbar>
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #byQuantity let-minOrder="minOrder">
  <div class="d-none d-lg-flex ka-grey-card">
    <div class="item">
      <div class="image red-circle">
        <svg class="mx-auto tabler-bucket">
          <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-bucket" />
        </svg>
      </div>
      <div class="content">
        <div class="text">
          <span>{{ ROOT + 'MINIMUM_BOXES' | translate }} </span>
          <span>{{ minOrder.value }}</span>
        </div>
        <p class="my-2">
          <ngb-progressbar 
            [type]="percentNumberBoxes >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
            [value]="percentNumberBoxes">
          </ngb-progressbar>
        </p>
      </div>
    </div>
  </div>

  <div class="d-flex d-lg-none mobile">
    <div class="item">
      <div class="content">
        <div class="text">
          <span>{{ ROOT + 'MINIMUM_BOXES' | translate }} </span>
          <span>{{ minOrder.value }}</span>
        </div>
        <p class="my-2">
          <ngb-progressbar 
            [type]="percentNumberBoxes >= 100 ? ProgressBarStatus.COMPLETED : ProgressBarStatus.INCOMPLETED" 
            [value]="percentNumberBoxes">
          </ngb-progressbar>
        </p>
      </div>
    </div>
  </div>
</ng-template>

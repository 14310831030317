<div class="modal-header">
  <div class="d-flex align-items-center flex-column w-100 p-4">
    <img class="img-fluid w-50" src="{{ './assets/media/emojis/hand-up.webp' | toThumbnail:true }}" alt="reload-required-img" />
  </div>
</div>
<div class="d-flex justify-content-center flex-column align-items-center m-5">
  <h1 class="text-center">{{ 'EXPIRE_SESSION.TEXT_1' | translate }}</h1>
  <div class="fs-2">{{ 'EXPIRE_SESSION.TEXT_2' | translate }}</div>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button type="button" class="w-50 btn-square-black" id="session-expired-modal" (click)="confirm()">{{ 'EXPIRE_SESSION.LOGIN' | translate }}</button>
</div>
@let canShowMinOrderLabel = showMinAmountByDates && minOrder && minOrder?.value > 0;

@if (canShowMinOrderLabel) {
  <div class="radio-option-min-amount" [ngClass]="{'green-text': showGreenTextMinAmount}">
    
    @if (showGreenTextMinAmount) {
      <svg class="icon">
        <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-truck" />
      </svg>
    }

    {{
      minOrder.isByQuantity 
        ? (minOrder.value | boxes) 
        : (
            'NEW_ORDER.DELIVERY_PLAN.MINIMUM_AMOUNT' | translate: { 
              amount: minOrder.value | currencyWithoutDecimals 
            }
          )
    }}  
    
  </div>
}

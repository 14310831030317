<div
  *ngIf="
    !selectedClient?.hasLockOrder && hasvisitDates && isTopBarCartAvailable
  "
  [matBadge]="
    hasDraftItems
      ? (quantityItems
        | notificationQuantityCalculation: selectedClient : user
        | notificationMaxProduct)
      : null
  "
  matBadgeSize="small"
  matBadgeColor="warn"
  class="cart-notification"
  (click)="hasDraftItems ? goOrderDetail() : goPortfolio(false)"
>
  <img class="cart-icon" [src]="'./assets/icons/cart.svg'" alt="Cart Icon" />
</div>
<div *ngIf="selectedClient?.hasLockOrder || !hasvisitDates">
  <img class="cart-icon" [src]="'./assets/icons/cart.svg'" alt="Cart Icon" />
</div>

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewslettersService } from 'src/app/core/services/newsletters.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserInfo } from 'src/app/core/models/user-info.model';

@Component({
    selector: 'app-newsletters-popup-modal',
    templateUrl: './newsletters-popup-modal.component.html',
    styleUrls: ['./newsletters-popup-modal.component.scss']
})
export class NewslettersPopupModalComponent implements OnInit {

    @Input() public data: any;
    @Input() public newsletters: any[];
    @Input() public showFooterButtons? = true;
    @Output() modalResponse: EventEmitter<any> = new EventEmitter();

    private subscriptions = new Subscription();
    user: UserInfo;

    //el newsletter activo sera el que se muestre en el modal 
    // mientras que las los botones de izquierda y derecha iran desplazandose por el array newsletters
    public activeNewsletter: any;
    public newsletterIndex = 0;
    public newsletterImgDsk = '../../../assets/media/backgrounds/popup-desktop.webp';
    public newsletterImgMb = '../../../assets/media/backgrounds/popup-mobile.webp';
    screenWidth: number = 0;

    constructor(
        public activeModal: NgbActiveModal,
        public router: Router,
        public newslettersService: NewslettersService,
         private store: Store<{ user: UserInfo }>,
        ) {
            this.subscriptions.add(this.store.select('user').subscribe((user) => {
                (this.user = user)
            }));
         }

    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.activeNewsletter = this.newsletters[this.newsletterIndex];
        this.setNewsletterStatus();
        this.getActiveNewsletterImg();
    }

    previousNewsletter():void {
        if(this.newsletterIndex>0){
            this.newsletterIndex--;
            this.activeNewsletter = this.newsletters[this.newsletterIndex];
            this.setNewsletterStatus();
            this.getActiveNewsletterImg();
        }
    }

    nextNewsletter():void {
        if(this.newsletterIndex<this.newsletters.length-1){
            this.newsletterIndex++;
            this.activeNewsletter = this.newsletters[this.newsletterIndex];
            this.setNewsletterStatus();
            this.getActiveNewsletterImg();
        }
    }

    confirm(): void {
        
        this.router.navigate([`/main/newsletters/newsletter-detail/${this.activeNewsletter.id}`],
            {
              state: { title: this.activeNewsletter.title }
            });
        
        this.activeModal.close(true);
    }

    reject(): void {
        this.activeModal.close(false);
    }

    getActiveNewsletterImg():void{
        const imgDsk = this.newsletters[this.newsletterIndex].imageKeys.find(img => img.type === 'popDesktop')?.image;
        const imgMb = this.newsletters[this.newsletterIndex].imageKeys.find(img => img.type === 'popMobile')?.image;
        if(imgDsk){
            this.newsletterImgDsk = imgDsk;
        }else{
            this.newsletterImgDsk = '../../../assets/media/backgrounds/popup-desktop.webp';
        }
        if(imgMb){
            this.newsletterImgMb = imgMb;
        }else{
            this.newsletterImgMb = '../../../assets/media/backgrounds/popup-mobile.webp';
        }
        
    }

    setNewsletterStatus(){
        this.newslettersService.setNewsletterStatus(this.activeNewsletter.id, this.user.email, 'POPUP').subscribe();
    }

    getOptimizedImageUrl(imageUrl: string): string {
        const webpUrl = imageUrl.replace(/\.(png|jpg)$/, '.webp');
        return webpUrl;
      }
      
    onImageError(ev, originalUrl): void {
    const imgElement = ev.target as HTMLImageElement;
    imgElement.src = originalUrl;
    }

}
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  FavoritesService,
  notificationInfo,
} from 'src/app/core/services/favorites.service';

@Component({
  selector: 'app-ka-fav-notifications',
  templateUrl: './ka-fav-notifications.component.html',
  styleUrls: ['./ka-fav-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KaFavNotificationsComponent implements OnInit {
  public notification: notificationInfo;
  public showNotification = false;

  private favoriteService = inject(FavoritesService);
  private destroyRef = inject(DestroyRef);
  private ref = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.favoriteService
      .getSharingFavoriteNotifications()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((notification: notificationInfo) => {
        this.notification = notification;
        this.showNotification = !this.showNotification;
        this.ref.detectChanges();

        setTimeout(() => {
          this.showNotification = !this.showNotification;
          this.ref.detectChanges();
        }, notification.delay);
      });
  }
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { startWith, distinctUntilChanged } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

import { VisitFrozenDatesService } from 'src/app/core/services/visit-frozen-dates.service';
import { ClientService } from 'src/app/core/services/client.service';

import { WeekdayDayMonthParserPipe } from '../../pipes/week-day-day-month.pipe';

import { RadioGroup } from '@components/radio/directives/radio-group.directive';
import { RadioComponent } from '@components/radio/radio.component';

import { Client, VisitDate } from 'src/app/core/models/client.model';

import { ComponentsModule } from '@components/components.module';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

import { MinOrderLabelComponent } from '../min-order-label/min-order-label.component';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    ComponentsModule,
    ReactiveFormsModule,
    WeekdayDayMonthParserPipe,
    RadioComponent,
    RadioGroup,
    CommonModule,
    MinOrderLabelComponent
  ],
  selector: 'app-options-frozen-delivery-date',
  templateUrl: './options-frozen-delivery-date.component.html',
  styleUrls: ['./options-frozen-delivery-date.component.scss']
})
export class OptionsFrozenDeliveryDateComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription;
  readonly countryCodes = CountryCodes;
  control: FormControl = this.formBuilder.control('');

  get selectedVisitDate(): string | Date {
    return this.visitFrozenDatesService.selectedVisitDate;
  }

  get client$(): Observable<Client> {
    return this.clientService.getClientUntilChanged$();
  }

  get visitDates(): VisitDate[] {
    return this.visitFrozenDatesService.visitDates;
  }

  @Output() selectedFrozenVisitDate = new EventEmitter<VisitDate>();

  constructor(
    private visitFrozenDatesService: VisitFrozenDatesService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.getVisitDatesByClient();
    this.setUpForm();
  }

  private getVisitDatesByClient(): void {
    this.subscriptions.add(
      this.visitFrozenDatesService.getVisitDatesByClient()
    );
  }

  private setUpForm(): void {
    this.subscriptions.add(
      this.visitFrozenDatesService.clientCartStream$.subscribe({
        next: () => {
          this.control = this.formBuilder.control(this.getInitialValue());
          this.updateSelectVisitDate();

          if (this.visitFrozenDatesService.visitDates.length === 1) {
            this.control.disable();
          }
        },
      })
    );
  }

  private getInitialValue(): VisitDate {
    let initialControlValue: VisitDate;

    this.visitFrozenDatesService.visitDates?.forEach((date) => {
      if (this.visitFrozenDatesService.isSameDeliveryDate(date)) {
        initialControlValue = date;
      }
    });

    return initialControlValue
      ? initialControlValue
      : this.visitFrozenDatesService.visitDates[0];
  }

  private updateSelectVisitDate(): void {
    this.subscriptions.add(
      this.control.valueChanges.pipe(
        startWith(this.control.value),
        distinctUntilChanged((prev: VisitDate, curr: VisitDate) => {
          return prev.visitDate === curr.visitDate;
        }),
      ).subscribe({
        next: (value) => {
          this.selectedFrozenVisitDate.emit(value)
        },
      })
    );
  }

  trackByDates(date: VisitDate): string {
    return date.visitDate;
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Cart } from '../../models/cart.model';
import { ProductsCalcs } from '../../utils/products-calcs';


export const getCartFeatureState = createFeatureSelector<Cart>('cart');

export const getCartProducts = createSelector(getCartFeatureState, (state) => state.products);
export const getProductsAndDiscounts = createSelector(getCartFeatureState, (state) => state.discountProducts);

export const getCartNonFrozenProducts = createSelector(getProductsAndDiscounts, (products) => products.filter((product) => product.deliveryType === 'delivery'));
export const getCartFrozenProducts = createSelector(getProductsAndDiscounts, (products) => products.filter((product) => product.deliveryType == 'deliveryfrozen'));

export const getCartSubTotalPrice = createSelector(getProductsAndDiscounts, (products) => ProductsCalcs.getTotalListPrice(products));
export const getCartTotalFinalTaxes = createSelector(getProductsAndDiscounts, (products) => ProductsCalcs.getTotalFinalTaxes(products));
export const getCartSubtotalsByGroup = createSelector(getProductsAndDiscounts, (products) => ProductsCalcs.getSubtotalsByGroup(products));
export const getCartSavings = createSelector(getProductsAndDiscounts, (products) => ProductsCalcs.getTotalDiscounts(products));

export const getCartTotalBoxes = createSelector(getProductsAndDiscounts, (products) => ProductsCalcs.getTotalBoxes(products));

export const hasCartDeliveryFrozenProducts = createSelector(getCartFrozenProducts, (products) => products.length > 0);
export const hasCartDeliveryNotFrozenProducts = createSelector(getCartNonFrozenProducts, (products) => products.length > 0);

export const getCartTotalPrice = createSelector(getProductsAndDiscounts, (products) => ProductsCalcs.getTotalFinalPrice(products));
export const getCartTotalPriceFrozen = createSelector(getCartFrozenProducts, (products) => ProductsCalcs.getTotalFinalPrice(products));
export const getCartTotalPriceNotFrozen = createSelector(getCartNonFrozenProducts, (products) => ProductsCalcs.getTotalFinalPrice(products));

export const getCartVisitDate = createSelector(getCartFeatureState, (state) => state.visitDate);
export const getCartFrozenVisitDate = createSelector(getCartFeatureState, (state) => state.frozenVisitDate);

export const getCartInvoiceDeadline = createSelector(getCartFeatureState, (state) => state.invoiceDeadline);

export const getCartPaymentMethod = createSelector(getCartFeatureState, (state) => state.paymentMethod);

export const getFridayMessageShow = createSelector(getCartFeatureState, (state) => state.fridayMessage.show);

export const getCartMinOrderAmbient = createSelector(getCartFeatureState, (state) => state.minOrderAmbient);
export const getCartMinOrderFrozen = createSelector(getCartFeatureState, (state) => state.minOrderFrozen);
import { Pipe, PipeTransform } from '@angular/core';
import { DiscountTypes } from '../../core/enums/discount-types';
import { Discount } from '../../core/models/discount.model';

@Pipe({
  name: 'showAddButtonOnCard',
})
export class ShowAddButtonOnCardPipe implements PipeTransform {
  transform(discount: Discount): boolean {
    let isAddButton = false;
    switch (discount.discountType) {
      case DiscountTypes.CLOSED:
      case DiscountTypes.SCALE:
      case DiscountTypes.UNIT_SCALE:
      case DiscountTypes.BOM:
        isAddButton = true;
        break;
      case DiscountTypes.SCALE_AMOUNT:
      case DiscountTypes.PERCENTAGE:
      case DiscountTypes.AMOUNT:
      case DiscountTypes.FIXED:
        isAddButton = discount.config.class === 'close';
        break;
    }
    return isAddButton;
  }
}

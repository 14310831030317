import { VisitDate } from 'src/app/core/models/client.model';
import { MinOrder } from '../interface/min-order.interface';
import { DeliveryType } from '../../cart.service';

export function createMinOrder(visit: VisitDate): MinOrder {
  const isOffRoute = visit.offRoute || false;

  const keys = Object.keys(visit);
  const isByQuantity = keys.some(key => key.includes('quantity'));

  const isFrozen = visit.visitType === DeliveryType.FROZEN;

  const valueKey = keys.find(key => key.includes('amount') || key.includes('quantity'));
  const value = valueKey ? visit[valueKey] : 0;

  return {
    value,
    isOffRoute,
    isByQuantity,
    isFrozen
  };
}
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ProductsService } from '../../services/products.service';

import { BERespModel } from '../../models/backend/BE-response.model';
import { PortfolioFilters } from '../../models/portfolio-filters.model';

import * as PortfolioActions from './../actions/portfolio.actions';
import {
  getPortfolioProductsFilters,
  selectPortfolioCurrentOffSet,
} from '../selectors/portfolio.selectors';

@Injectable({
  providedIn: 'root',
})
export class PortfolioEffects {
  constructor(
    private productsService: ProductsService,
    private actions$: Actions,
    private store: Store,
  ) {}

  loadPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.loadPortfolio),
      withLatestFrom(this.store.select(getPortfolioProductsFilters)),
      mergeMap(([props, productFilters]) =>
        this.productsService
          .getPortfolioWithDiscountByClient(true, productFilters, props.offset)
          .pipe(
            switchMap((res: BERespModel) => {
              return [
                PortfolioActions.loadPortfolioSuccess({
                  products: res.data,
                  page: res.pagination.currentpage,
                  totalProducts: res.pagination.count,
                }),
              ];
            }),
            catchError((error) => {
              return of(PortfolioActions.loadPortfolioError({ error }));
            }),
          ),
      ),
    ),
  );

  getDataPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.getDataPortfolio),
      switchMap(({ productFilters, offset }) =>
        this.productsService
          .getPortfolioWithDiscountByClient(true, productFilters, offset)
          .pipe(
            map((res: BERespModel) =>
              PortfolioActions.getDataPortfolioSuccess({
                products: res.data,
                page: res.pagination.currentpage,
                totalProducts: res.pagination.count,
              }),
            ),
            catchError((error) =>
              of(PortfolioActions.getDataPortfolioFailure({ error })),
            ),
          ),
      ),
    ),
  );

  refreshPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.refreshPortfolio),
      withLatestFrom(
        this.store.select(getPortfolioProductsFilters),
        this.store.select(selectPortfolioCurrentOffSet),
      ),
      mergeMap(([_action, productFilters, offset]) =>
        this.productsService
          .getPortfolioWithDiscountByClient(true, productFilters, offset)
          .pipe(
            switchMap((res: BERespModel) => {
              return [
                PortfolioActions.loadPortfolioSuccess({
                  products: res.data,
                  page: res.pagination.currentpage,
                  totalProducts: res.pagination.count,
                }),
              ];
            }),
            catchError((error) => {
              return of(PortfolioActions.loadPortfolioError({ error }));
            }),
          ),
      ),
    ),
  );

  loadProductsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.loadPortfolioFilter),
      mergeMap((props) =>
        this.productsService.getPortfolioFilters(props.productFilters).pipe(
          switchMap((res: BERespModel) => {
            const portfolioFilters: PortfolioFilters = {
              brands: res.data?.brands,
              categories: res.data?.categories,
              packages: res.data?.packages,
              sizes: res.data?.sizes,
              returnabilities: res.data?.returnabilities,
              principalFilters: res.data?.principalFilters,
            };
            return [
              PortfolioActions.loadPortfolioFilterSuccess({ portfolioFilters }),
            ];
          }),
          catchError((error) =>
            of(PortfolioActions.loadPortfolioFilterError({ error })),
          ),
        ),
      ),
    ),
  );
}

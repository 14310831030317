import { Pipe, PipeTransform } from '@angular/core';
import { DiscountTypes } from '../../core/enums/discount-types';

@Pipe({
  name: 'showSelectButtonOnCard',
})
export class ShowSelectButtonOnCardPipe implements PipeTransform {
  transform(discount): boolean {
    let isSelectButton = false;
    switch (discount.discountType) {
      case DiscountTypes.OPEN:
      case DiscountTypes.PACK_SCALE:
        isSelectButton = true;
        break;
      case DiscountTypes.SCALE_AMOUNT:
      case DiscountTypes.PERCENTAGE:
      case DiscountTypes.AMOUNT:
      case DiscountTypes.FIXED:
        isSelectButton = discount.config.class !== 'close';
        break;
    }
    return isSelectButton;
  }
}

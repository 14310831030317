import { Component, Input } from '@angular/core';
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";

import { Cart } from "../../../core/models/cart.model";
import { ProductFilter } from 'src/app/core/models/product-filter.model';

import * as PortfolioActions from 'src/app/core/state/actions/portfolio.actions';

import { getActualStatusForFilters } from 'src/app/core/utils/file-utils';
import { INITIAL_PRODUCT_FILTERS } from 'src/app/core/state/reducers/portfolio.reducer';

export type defaultFiltersType = 'brand' | 'category' | 'package' | 'size';
export type specialFiltersType = 'isDiscount' | 'returnability' | 'isFavorite';

@Component({
  selector: 'app-ka-filters',
  templateUrl: './ka-filters.component.html',
  styleUrls: ['./ka-filters.component.scss'],
})
export class KaFiltersComponent {
  @Input() productFilter: ProductFilter;
  @Input() filters = { package: '', brand: '', size: '' };
  @Input() category = { key: 'category', value: '' };

  ROOT_LANG = 'NEW_ORDER.PRODUCTS.FILTER.';
  ROOT_LANG_1 = 'NEW_ORDER.MORE_PRODUCTS.';

  heartImage = false;

  constructor(
    private store: Store<{ cart: Cart }>,
    ngbMenu: NgbDropdownConfig
  ) {
    ngbMenu.placement = 'bottom';
  }

  updateFilters(field: defaultFiltersType, value: string) {
    const productFilter = {
      ...this.productFilter
    };
    productFilter[field] = value;

    this.updateProductFilterState(productFilter);
  }

  cleanFilters() {
    const productFilters: ProductFilter = {
      ...INITIAL_PRODUCT_FILTERS
    };

    this.updateProductFilterState(productFilters);
  }

  updateSpecificFilter(field: specialFiltersType): void {
    if(field==='isFavorite') this.heartImage = !this.heartImage;
    const productFilters: ProductFilter = {
      ...this.productFilter,
    };
    productFilters[field] = getActualStatusForFilters(this.productFilter[field]);

    this.updateProductFilterState(productFilters);
  }

  private updateProductFilterState(productFilters: ProductFilter): void {
    this.store.dispatch(
      PortfolioActions.updatePortfolioFilter({
        productFilters: { ...productFilters }
      })
    );
  }
}

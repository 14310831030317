import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { Cart } from '../models/cart.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { deleteAllProductsFrozen, deleteAllProductsNotFrozen } from '../state/actions/cart.actions';
import { DocsViewModalComponent } from 'src/app/shared/modals/docs-view-modal/docs-view-modal.component';
import { RepeatOrderModalComponent } from 'src/app/pages/new-order/modals/repeat-order-modal/repeat-order-modal.component';
import { CartService } from './cart.service';
import { Router } from '@angular/router';

import { UserInfo } from '../models/user-info.model';
import { PassAttempModalComponent } from 'src/app/shared/modals/pass-attemp-modal/pass-attemp-modal.component';
import { PpdBlockComponent } from 'src/app/shared/modals/ppd-block/ppd-block.component';
import { environment } from 'src/environments/environment';
import { NewslettersPopupModalComponent } from 'src/app/shared/modals/newsletters-popup-modal/newsletters-popup-modal.component';
import { ToThumbnailPipe } from 'src/app/shared/pipes/to-thumbnail.pipe';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private subscriptions = new Subscription();
  cart: Cart;
  cartService: CartService;
  user: UserInfo;
  myOrdersRoute: string;

  constructor(
    cartService: CartService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private router: Router,
    private store: Store<{ user: UserInfo; cart: Cart }>,
    private toThumbnailPipe: ToThumbnailPipe
  ) {
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));
    this.subscriptions.add(this.store.select('user').subscribe((user) => (this.user = user)));
    config.backdrop = 'static';
    config.keyboard = false;
    this.cartService = cartService;
  }

  openClientOrderBlockedModal(): void {
    const modalConfirm = this.modalService.open(ConfirmModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'BLOCK_USER.';
    modalConfirm.componentInstance.data = {
      id: 'accept-block-client-modal',
      customClass: 'blocked-client',
      image: this.toThumbnailPipe.transform('tristecito.webp', true),
      text: ROOT_LANG + 'TITLE',
      description: ROOT_LANG + 'SUBTITLE_1',
      confirmBtnLabel: !this.user.uuid ? ROOT_LANG + 'ACCEPT' : '',
      hideCloseButton: true,
    };
    modalConfirm.result.then(
      () => {},
      () => {
        if (this.router.url.includes('/main/nuevo-pedido/')) this.router.navigate(['/main/home']);
      }
    )
  }

  openMaintenanceModal(): void {
    const modalConfirm = this.modalService.open(ConfirmModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'MAINTENANCE.MODAL.';
    modalConfirm.componentInstance.data = {
      id: 'accept-maintenance-modal',
      customClass: 'maintenance-modal',
      image: this.toThumbnailPipe.transform('in-construction.webp', true),
      text: ROOT_LANG + 'TITLE',
      description:  ROOT_LANG + 'SUBTITLE',
      action: ROOT_LANG + 'ACTION',
      whatsapp: ROOT_LANG + 'WHATSAPP',
      hideCloseButton: false,
      whatsappLabel: ROOT_LANG + 'ACCEPT',
    };
    modalConfirm.result.then(
      () => {},
      () => {
        if (this.router.url.includes('/main/nuevo-pedido/')) this.router.navigate(['/main/home']);
      }
    )
  }

  openCleanCartConfirm(deliveryType): void {
    const modalConfirm = this.modalService.open(ConfirmModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'CLEAN_CART_MODAL.';
    modalConfirm.componentInstance.data = {
      id: 'clean-cart',
      text: ROOT_LANG + 'TEXT',
      description: ROOT_LANG + 'DESCRIPTION',
      confirmBtnLabel: ROOT_LANG + 'CONFIRM',
      rejectBtnLabel: ROOT_LANG + 'REJECT',
    };
    modalConfirm.result.then(
      (confirm) => {
        if (confirm) {

          if (deliveryType === 'deliveryfrozen') {
            this.store.dispatch(deleteAllProductsFrozen());
          }

          if (deliveryType === 'delivery' || !deliveryType) {
            this.store.dispatch(deleteAllProductsNotFrozen());
          }

          this.cartService.updateDeliveryProducts();
        }
      },
      (rejected) => {}
    );
  }

  openConditionsModal(): void {
    const modalConfirm = this.modalService.open(ConfirmModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'TYC_MODAL.';
    modalConfirm.componentInstance.data = {
      id: 'docs-view-modal',
      customClass: 'docs-view',
      text:  ROOT_LANG + 'TEXT',
      description: ROOT_LANG + 'DESCRIPTION',
      confirmBtnLabel: ROOT_LANG + 'CONFIRM',
    };
  }

  openSessionExpiredModal(): void {
    const modalConfirm = this.modalService.open(ConfirmModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'NEW_ORDER.EXTERNAL_INTEGRATION.';
    modalConfirm.componentInstance.data = {
      backdrop: true,
      hideCloseButton: true,
      id: 'docs-view-modal',
      image: this.toThumbnailPipe.transform('not-found.webp', true),
      customClass: 'expired-session-modal',
      text:  ROOT_LANG + 'TITLE',
      description: ROOT_LANG + 'SESSION_EXPIRED',
      confirmBtnLabel: ROOT_LANG + 'RETURN_TO_WHATSAPP',
    };
    modalConfirm.result.then(
      (confirm) => {
        if (confirm) {
            const phoneNumber = environment.kobossWhatsapp;
            const whatsappUrl = `https://wa.me/${phoneNumber}`;
            window.location.href = whatsappUrl;
        }
      }
    );
  }

  openPaymentContingencyModal(): void {
    const modalConfirm = this.modalService.open(ConfirmModalComponent, {windowClass: 'ngbmodal-centered'});
    const ROOT_LANG = 'MISSING_PAYMENT_METHOD.';
    modalConfirm.componentInstance.data = {
      id: 'docs-view-modal',
      image: this.toThumbnailPipe.transform('tristecito.webp', true),
      customClass: 'docs-view',
      text: ROOT_LANG + 'TEXT',
      description: ROOT_LANG + 'DESCRIPTION',
      confirmBtnLabel: this.router.url.includes('mcc') ? ROOT_LANG + 'ACCEPT' : ROOT_LANG + 'CONFIRM',
      hideCloseButton: true,
    };
    modalConfirm.result.then(
        (confirm) => {
          if (confirm) {
            let ordersRoute = this.router.url.includes('mcc') ? '/main/nuevo-pedido/1' : '/main/home';
            this.router.navigate([ordersRoute]);
          }
        }
    );
  }

  openDocsViewModal(title: String, fileName: String, nameDoc: String, nameBtn: String): void {
    const modalConfirm = this.modalService.open(DocsViewModalComponent, { windowClass: 'ngbmodal-centered', size: 'xl' });
    modalConfirm.componentInstance.data = {
      id: 'docs-view-modal',
      customClass: 'docs-view',
      fileName: fileName,
      titleModal: title,
      nameDoc: nameDoc,
      nameBtn: nameBtn,
    };
  }

  openRepeatedOrder(): any {
    return this.modalService.open(RepeatOrderModalComponent, { windowClass: 'ngbmodal-centered' });
  }

  openCancellationOrder(): NgbModalRef {
    const modalCancellationOrder = this.modalService.open(ConfirmModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'CANCELL_ORDER_MODAL.';
    modalCancellationOrder.componentInstance.data = {
      id: 'cancellation-order-modal',
      image: this.toThumbnailPipe.transform('blush.webp', true),
      text: ROOT_LANG + 'TEXT',
      description: ROOT_LANG + 'DESCRIPTION',
      confirmBtnLabel: ROOT_LANG + 'CONFIRM',
      rejectBtnLabel: ROOT_LANG + 'REJECT',
      btnWide: true,
    };
    return modalCancellationOrder;
  }

  openPassAttemptsFailedModal(): NgbModalRef {
    const PassAttemptsFailedModal = this.modalService.open(PassAttempModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'LOGIN.SIGN_IN.PASS_ATTEMPT_FAIL.';
    PassAttemptsFailedModal.componentInstance.data = {
      id: 'pass-attemps-failed-modal',
      image: this.toThumbnailPipe.transform('tristecito.webp', true),
      text: ROOT_LANG + 'TEXT',
      description: ROOT_LANG + 'DESCRIPTION',
      alertText: ROOT_LANG + 'ALERT_TEXT',
      confirmBtnLabel: ROOT_LANG + 'CONFIRM_BUTTON_LABEL',
      btnWide: true,
    };
    return PassAttemptsFailedModal;
  }

  openRestartOrderProducts(): void {
    const modalConfirm = this.modalService.open(ConfirmModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'MY_ORDERS.DRAFT_MODAL.';
    modalConfirm.componentInstance.data = {
      image: this.toThumbnailPipe.transform('tristecito.webp', true),
      text: ROOT_LANG + 'SOMETHING_WENT_WRONG',
      description: ROOT_LANG + 'CLOSE_YOUR_ORDER',
      additionalDescription: ROOT_LANG + 'YOUR_SELECTION_WILL_BE_SAVED',
      confirmBtnLabel: ROOT_LANG + 'RESTART_ORDER',
      btnWide: true,
      hideCloseButton: true,
    };
    modalConfirm.result.then(
        (confirm) => {
          if (confirm) {
            this.myOrdersRoute = this.router.url.includes('mcc') ? '/mcc/1' : '/main/nuevo-pedido/1';
            this.router.navigate([this.myOrdersRoute], { queryParams: { draft: true } });
          }
        }
    );
  }

  openPPDBlockModal(): void {
    this.modalService.open(PpdBlockComponent, { windowClass: 'ngbmodal-centered' });
  }

  openNewslettersPopupModal(newsletters:any[]): void {
    const modal = this.modalService.open(NewslettersPopupModalComponent, { windowClass: 'ngbmodal-centered' });
    const ROOT_LANG = 'NEWSLETTERS_POPUP_MODAL.';
    modal.componentInstance.newsletters = newsletters;
    modal.componentInstance.data = {
      btnWide: true,
      hideCloseButton: false,
    };  
    
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

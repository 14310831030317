import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Client } from '../../models/client.model';

const getClientFeatureState = createFeatureSelector<Client>('client');

export const getSuggestedProducts = createSelector(
  getClientFeatureState,
  (state) => state.data?.suggestedProducts,
);

export const getSuggestedProductsList = createSelector(
  getSuggestedProducts,
  (suggestedProduct) => suggestedProduct.products,
);

export const selectSuggestedProductsCounter = createSelector(
  getClientFeatureState,
  (state) => state.data?.suggestedProducts?.products?.length,
);

export const selectSuggestedProductsTotalAmount = createSelector(
  getClientFeatureState,
  (state) => state.data?.suggestedProducts?.amount,
);

export const selectHasLockProductCategory = createSelector(
  getClientFeatureState,
  (state) => state.hasLockProductCategory,
);

export const selectClientDraftsProduct = createSelector(
  getClientFeatureState,
  (state) => state.data?.drafts || [],
);

export const selectClientFrozenVisitDates = createSelector(
  getClientFeatureState,
  (state) => state.data?.frozenVisitDates || [],
);

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ProductTypes } from 'src/app/core/enums/product-types';

@Component({
  selector: 'app-ka-suggested-view-button',
  templateUrl: './ka-suggested-view-button.component.html',
  styleUrls: ['./ka-suggested-view-button.component.scss'],
})
export class KaSuggestedViewButtonComponent {
  readonly ROOT_LANG = 'HOME.SUGGESTED_CAROUSEL';

  constructor(private router: Router) {}

  goToStep(idStep: string): void {
    const link = this.router.url.includes('mcc')
      ? '/mcc/' + idStep + '/'
      : '/main/nuevo-pedido/' + idStep + '/';

    this.router.navigate([link], {
      queryParams: { grupo: ProductTypes.SUGGESTED },
    });
  }
}

import { Component, Input, OnDestroy, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Portfolio } from 'src/app/core/models/portfolio.model';
import { ProductFilter } from 'src/app/core/models/product-filter.model';
@Component({
  selector: 'app-ka-categories-button',
  templateUrl: './ka-categories-button.component.html',
  styleUrls: ['./ka-categories-button.component.scss'],
})
export class KaCategoriesButtonComponent implements OnDestroy {
  private subscriptions = new Subscription();
  @Input() text: string;
  @Input() image: string;
  @Input() selected = false;
  @Output() clickEvent = new EventEmitter();
  public showButton = true;
  currentFilters: ProductFilter;

  constructor(private store: Store<{ portfolio: Portfolio }>) {
    this.subscriptions.add(
      this.store.select('portfolio').subscribe((portfolio) => {
        this.currentFilters = portfolio.productFilters;
      }),
    );
  }

  handleError() {
    this.showButton = false;
  }

  goToCategoryEvent() {
    this.clickEvent.emit(this.text);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

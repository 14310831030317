<div class="select-date-container">
  <div 
    *ngIf="title"
    class="select-date-title"
    [ngClass]="{'ka-border-bottom': !subtitle}">
    {{title | translate}}

    <ng-container *ngIf="(!showMinAmountByDates | canShowMinAmountByDates)">
      <div 
        class="text-minimum-amount"
        *ngIf="ambientOnOffSmartVisitDates[0].amountMinRoute">
        {{
          'NEW_ORDER.DELIVERY_PLAN.MINIMUM_AMOUNT' | translate: { amount: 
            (ambientOnOffSmartVisitDates[0].amountMinRoute | currencyWithoutDecimals)
          } 
        }}
      </div>

      <div 
        class="text-minimum-amount"
        *ngIf="ambientOnOffSmartVisitDates[0].quantityMinOffRoute">
        {{ ambientOnOffSmartVisitDates[0].quantityMinOffRoute | boxes }}
      </div>
    </ng-container>
  </div>
  
  <div 
    *ngIf="subtitle"
    class="select-date-subtitle ka-border-bottom">
    {{subtitle | translate}}
  </div>

  <div class="select-options-container">
    <app-options-delivery-date 
      (selectedVisitDate)="updateSelectedDate($event)">
    </app-options-delivery-date>
  </div>
</div>

<div class="select-date-buttons">
  <app-button
    [config]="{
      text: 'NEW_ORDER.DELIVERY_PLAN.BUTTON_SELECT_DELIVERY_DATE' | translate,
      style: 'primaryFilled',
    }"
    (clickEvent)="updateCurrentVisitDate()"
  ></app-button>
  <ng-container *ngIf="showCloseItems">
    <div class="mt-8"></div>
    <app-button
      [config]="{
        text: 'MY_ORDERS.UPDATE_DATE_DELIVERY_MODAL.BUTTON.CANCEL' | translate,
        style: 'primaryText',
      }"
      (clickEvent)="closeModal(eventSelectDateBtn.CLOSE_BTN)"
    ></app-button>
  </ng-container>
</div>
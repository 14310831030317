import { Component, OnInit } from '@angular/core';
import * as ClientActions from 'src/app/core/state/actions/client.actions';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import * as PortfolioActions from 'src/app/core/state/actions/portfolio.actions';

@Component({
  selector: 'app-ka-draft-request-banner',
  templateUrl: './ka-draft-request-banner.component.html',
  styleUrls: ['./ka-draft-request-banner.component.scss'],
})
export class KaMyDraftRequestBannerComponent implements OnInit {
  readonly LANG_ROOT = 'MY_ORDERS.DRAFT_BANNER.';
  selectedClient: Client;
  myOrdersRoute: string;
  private subscriptions = new Subscription();
  constructor(private router: Router, private store: Store<{ client: Client }>) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
      })
    );
  }

  continueDraft(): void {
    this.myOrdersRoute = this.router.url.includes('mcc') ? '/mcc/1' : '/main/nuevo-pedido/1';
    this.router.navigate([this.myOrdersRoute], { queryParams: { draft: true } });
  }

  deleteDraft(): void {
    this.store.dispatch(ClientActions.deleteDraft({ draft: this.selectedClient.data.drafts[0] }));
    this.store.dispatch(CartActions.cleanCart());
    this.store.dispatch(PortfolioActions.cleanProductFilters());
  }
}

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { refreshProductsAndPrices } from 'src/app/core/state/actions/cart.actions';

const newOrder = 'nuevo-pedido';

@Injectable({
  providedIn: 'root'
})
export class UpdateDiscountsPricesService {
  private store = inject(Store);
  private router = inject(Router);

  updateProductPricesAndDiscounts(): void {
    if (this.router.url.includes(newOrder)) {
      this.store.dispatch(refreshProductsAndPrices());
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import * as CartActions from 'src/app/core/state/actions/cart.actions';

import { Cart } from '../models/cart.model';
import { Client } from '../models/client.model';
import { UserInfo } from '../models/user-info.model';
import { PaymentMethodBySegement } from '../models/payment-method-by-segment.model';
import { FEATURES } from 'src/environments/utils/env.model';
import { MinOrder } from './min-amount/interface/min-order.interface';

import { ProductImageModalComponent } from 'src/app/shared/modals/product-image-modal/product-image-modal.component';
import { env } from '../../app.component';

import { NotificationInfo } from '../models/notification-info.type';
import { FridayMessage } from '../models/friday-message.model';
import { 
  getCartMinOrderAmbient, 
  getCartMinOrderFrozen, 
  getCartTotalBoxes, 
  getCartTotalPriceFrozen, 
  getCartTotalPriceNotFrozen, 
  hasCartDeliveryFrozenProducts, 
  hasCartDeliveryNotFrozenProducts 
} from '../state/selectors/cart.selectors';

export enum DeliveryType {
  FROZEN = 'deliveryfrozen',
  NO_FROZEN = 'delivery',
}

@Injectable({
  providedIn: 'root',
})
export class CartService implements OnDestroy {
  private subscriptions = new Subscription();
  cart: Cart;
  client: Client;
  user: UserInfo;
  cartHasDeliveryFrozenProducts?: boolean;
  cartHasDeliveryNotFrozenProducts?: boolean;
  cartTotalBoxesUser?: number;
  cartTotalPriceFrozen = 0;
  cartTotalPriceNotFrozen = 0;
  minOrderAmbient: MinOrder;
  minOrderFrozen: MinOrder;
  private notifications: Subject<NotificationInfo> =
    new Subject<NotificationInfo>();
  get cart$(): Observable<Cart> {
    return this.store.select('cart');
  }
  private fridayMessageSubject = new BehaviorSubject<FridayMessage>(null);
  fridayMessage$ = this.fridayMessageSubject.asObservable();

  notificationTypes = {
    PUSH: {
      type: 'PUSH',
      'alert-type': 'dark',
      delay: 5000,
      text: 'NEW_ORDER.RETURN_NOTIFICATION.MISSING_BOXES',
      missingQuantity: 3,
      percentage: 0.2,
      icon: 'percent-icon-black.svg',
      circularIconBackground: true,
      backgroundColor: '#FFDF01',
    },
  };

  constructor(
    private store: Store<{ cart: Cart; client: Client; user: UserInfo }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngbModalService: NgbModal,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );

    this.subscriptions.add(
      this.store
        .select((state) => state.cart.fridayMessage)
        .subscribe((fridayMessage) => {
          this.fridayMessageSubject.next(fridayMessage);

          if (fridayMessage?.show) {
            this.dispatchNotification('PUSH');
          }
        }),
    );

    this.subscriptions.add(this.store.select(hasCartDeliveryFrozenProducts).subscribe((hasDeliveryFrozenProducts) => (this.cartHasDeliveryFrozenProducts = hasDeliveryFrozenProducts)));
    this.subscriptions.add(this.store.select(hasCartDeliveryNotFrozenProducts).subscribe((hasDeliveryNotFrozenProducts) => (this.cartHasDeliveryNotFrozenProducts = hasDeliveryNotFrozenProducts)));
    this.subscriptions.add(this.store.select(getCartTotalBoxes).subscribe((cartTotalBoxes) => (this.cartTotalBoxesUser = cartTotalBoxes)));
    this.subscriptions.add(this.store.select(getCartTotalPriceFrozen).subscribe((cartTotalPriceFrozen) => (this.cartTotalPriceFrozen = cartTotalPriceFrozen)));
    this.subscriptions.add(this.store.select(getCartTotalPriceNotFrozen).subscribe((cartTotalPriceNotFrozen) => (this.cartTotalPriceNotFrozen = cartTotalPriceNotFrozen)));
    this.subscriptions.add(this.store.select(getCartMinOrderAmbient).subscribe({ next: (minOrderAmbient) => this.minOrderAmbient = minOrderAmbient }));
    this.subscriptions.add(this.store.select(getCartMinOrderFrozen).subscribe({ next: (minOrderFrozen) => this.minOrderFrozen = minOrderFrozen }));
  }

  // TODO deprecate this
  updateDeliveryProducts(): void {
    this.store.dispatch(CartActions.updateHasDeliveryFrozenProducts());
    this.store.dispatch(CartActions.updateHasDeliveryNotFrozenProducts());
  }

  shouldContinueButtonDisable(): boolean {
    const isFrozenAmountValid = this.hasFrozenProducts() ? this.isFrozenProductsMinAmountCompleted() : true;
    const isAmbientAmountValid = this.hasNonFrozenProducts() ? this.isNonFrozenProductsMinAmountCompleted() : true;

    const isMinOrderCompleted = isAmbientAmountValid && isFrozenAmountValid;
    const hasSufficientSubunits = this.client.subUnitAvailable
      ? this.checkSubunitsCompleteOrder()
      : true;
    const hasProducts =
      this.cartHasDeliveryFrozenProducts ||
      this.cartHasDeliveryNotFrozenProducts;

    return (isMinOrderCompleted && hasProducts && hasSufficientSubunits);
  }

  paymentMethodAllowed() {
    return this.cart.paymentMethod !== null;
  }

  getNewOrderSteps(): any[] {
    return [
      {
        id: 1,
        langKey: 'DATE',
        active: false,
        icon: 'calendar-event',
      },
      {
        id: 2,
        langKey: 'ORDER',
        active: false,
        icon: 'shopping-cart',
        showCart: true,
        shouldEndBuy: true,
      },
      {
        // My Order mobile
        id: 3,
        langKey: '',
        active: false,
        icon: '',
        isMobile: true,
      },
      {
        id: 4,
        langKey: 'DETAIL',
        active: false,
        icon: 'file-invoice',
      },
      {
        id: 5,
        langKey: 'MINI-CREDIT',
        active: false,
        icon: 'credit-card',
        shouldStepShow: this.user.countryId === 'AR',
      },
      {
        id: 6,
        langKey: 'CONFIRMATION',
        active: false,
        icon: 'truck',
      },
    ];
  }

  changeStep(step, primaryFilter?): void {
    const route = this.router.url.includes('mcc')
      ? '/mcc/' + step
      : '/main/nuevo-pedido/' + step;

    if (primaryFilter) {
      this.router.navigate([route], { queryParams: { grupo: primaryFilter } });
    } else {
      this.router.navigate([route]);
    }
  }

  checkSubunitsCompleteOrder() {
    const hasSubunits = this.cart.products.some(
      (product) => product.subunitSelected === 'BOT',
    );
    let enabledToConfirmOrder = true;
    if (hasSubunits) {
      enabledToConfirmOrder = this.cart.enabledToConfirmOrder;
    }
    return enabledToConfirmOrder;
  }

  repeatOrder(orderId: number): void {
    if (!orderId) return;
    const myOrdersRoute =
      this.router.url.includes('mcc') || this.user.uuid
        ? '/mcc/1'
        : '/main/nuevo-pedido/1';
    this.router.navigate([myOrdersRoute], { queryParams: { repeat: orderId } });
  }

  goOrderDetail(order): void {
    const isThanosAvailable = env.isFeatureAvailable(
      FEATURES.THANOS_ORDER_DETAIL,
    );

    if (!order.orderId && !isThanosAvailable) return;

    const myOrdersRoute =
      this.router.url.includes('mcc') || this.user.uuid
        ? '/mcc/mis-pedidos/detalles/'
        : '/main/mis-pedidos/detalles/';
    const orderId = order.orderId || order.erpOrderId;
    const queryParams = order.orderId ? null : { isExternal: true };
    this.router.navigate([myOrdersRoute + orderId], {
      relativeTo: this.activatedRoute.parent,
      queryParams,
    });
  }

  openImage(product): void {
    if (this.router.url != '/main/home') {
      const imageModal = this.ngbModalService.open(ProductImageModalComponent, {
        windowClass: 'ngbmodal-centered',
        size: 'md',
        backdrop: true,
      });
      imageModal.componentInstance.imageUrl = product.image;
    }
  }

  hasFrozenProducts(): boolean {
    return this.cart?.products?.some((product) => {
      return product?.deliveryType === DeliveryType.FROZEN;
    });
  }

  isFrozenProductsMinAmountCompleted(): boolean {
    return this.cartTotalPriceFrozen >= this.minOrderFrozen.value;
  }

  hasNonFrozenProducts(): boolean {
    return this.cart?.products?.some((product) => {
      return product?.deliveryType === DeliveryType.NO_FROZEN;
    });
  }

  isNonFrozenProductsMinAmountCompleted(): boolean {
    return this.minOrderAmbient.isByQuantity 
      ? this.minOrderAmbient.value <= this.cartTotalBoxesUser
      : this.minOrderAmbient.value <= this.cartTotalPriceNotFrozen;
  }

  isMinOrderAmbientByQuantityCompleted(): boolean {
    return this.minOrderAmbient.isByQuantity 
      ? this.cartTotalBoxesUser >= this.minOrderAmbient.value 
      : true;
  }

  getAccumulatedPaymentMethods(): PaymentMethodBySegement[] {
    if (!this.cart.multiplePaymentMethodsBySegment) {
      return [];
    }

    const paymentMethodsMap = {} as PaymentMethodBySegement;

    this.cart.multiplePaymentMethodsBySegment.forEach(
      (paymentMethodBySegment) => {
        const id = paymentMethodBySegment.paymentMethod.erpPaymentMethodId;

        if (!paymentMethodsMap[id]) {
          paymentMethodsMap[id] = { ...paymentMethodBySegment };
        } else {
          paymentMethodsMap[id].spent += paymentMethodBySegment.spent;
        }
      },
    );

    return Object.values(paymentMethodsMap);
  }

  clearStore(): void {
    this.store.dispatch(CartActions.cleanCart());
  }

  dispatchNotification(notificationType: string) {
    if (!this.fridayMessage$) return;
  
    const notification = {
      ...this.notificationTypes[notificationType],
      missingQuantity: this.getFridayMessageMissingQuantity(),
      percentage: this.getFridayMessagePercentage(),
    };
  
    this.notifications.next(notification);
  }

  getSharingNotifications(): Observable<NotificationInfo> {
    return this.notifications.asObservable();
  }

  getFridayMessageMissingQuantity() {
    return this.fridayMessageSubject.getValue()?.missingQuantity ?? 0;
  }

  getFridayMessagePercentage() {
    return (this.fridayMessageSubject.getValue()?.percentage ?? 0) * 100;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

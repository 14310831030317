import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Portfolio } from '../../models/portfolio.model';
import { PrincipalFilter } from '../../models/portfolio-filters.model';
import { ProductFilter } from '../../models/product-filter.model';
import { PrincipalFilterIds } from '../../enums/principal-filter-ids.enum';

export const getPortfolioFeatureState =
  createFeatureSelector<Portfolio>('portfolio');

export const selectPortfolioProducts = createSelector(
  getPortfolioFeatureState,
  (state) => state.productsAndDiscountsList,
);

export const selectPortfolioTotalProducts = createSelector(
  getPortfolioFeatureState,
  (state) => state.totalProducts,
);

export const getPortfolioProductsFilters = createSelector(
  getPortfolioFeatureState,
  (state) => state.productFilters,
);

export const selectPortfolioPrincipalFilters = createSelector(
  getPortfolioFeatureState,
  (state) => state.portfolioFilters?.principalFilters || [],
);

export const selectPortfolioPrincipalFiltersCategoryId = (name: string) =>
  createSelector(
    selectPortfolioPrincipalFilters,
    (principalFilters: PrincipalFilter[]) => {
      const category = principalFilters.find((filter) => filter.name === name);
      return category ? category.id : null;
    },
  );

export const selectPortfolioOptionsFilter = createSelector(
  getPortfolioFeatureState,
  (state) => {
    return {
      category: state.portfolioFilters.categories,
      package: state.portfolioFilters.packages,
      brand: state.portfolioFilters.brands,
      size: state.portfolioFilters.sizes,
    };
  },
);

export const selectPortfolioCurrentPrincipalFilter = createSelector(
  selectPortfolioPrincipalFilters,
  getPortfolioProductsFilters,
  (principalFilters: PrincipalFilter[], productFilter: ProductFilter) => {
    const namePredicate = (filter: PrincipalFilter) =>
      filter.name === productFilter.category;

    const discountPredicate = (filter: PrincipalFilter) =>
      filter.id === PrincipalFilterIds.Discounts;

    const favoritePredicate = (filter: PrincipalFilter) =>
      filter.id === PrincipalFilterIds.Favorites;

    const suggestedPredicate = (filter: PrincipalFilter) =>
      filter.id === PrincipalFilterIds.Suggested;

    if (productFilter.isSuggested) {
      return principalFilters.find(suggestedPredicate);
    }

    if (productFilter.isDiscount) {
      return principalFilters.find(discountPredicate);
    }
    if (productFilter.isFavorite) {
      return principalFilters.find(favoritePredicate);
    }
    if (productFilter.category) {
      return principalFilters.find(namePredicate);
    }
  },
);

export const selectPortfolioCurrentOffSet = createSelector(
  getPortfolioFeatureState,
  (portfolio) =>
    portfolio.productsAndDiscountsList.length * (portfolio.page - 1),
);
